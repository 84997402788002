import { map } from 'lodash';
import { useMemo } from 'react';
import {
  useCreateDriver,
  useDeleteDriver,
  useDrivers,
  useUpdateDriver,
  useUpateDriverLeave,
} from '../utils/api.hook';
import { usePagination } from '../shared/usePagintation';
import { DriversComponent } from './drivers.component';

export function DriversContainer() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });
  const { data: { driversInfo, total } = {}, isLoading: isFetchingDrivers } =
    useDrivers({
      skip: pagination.skip,
      take: pagination.take,
    });
  const { mutateAsync: createDriver, isLoading: isCreatingDriver } =
    useCreateDriver();
  const { mutateAsync: updateDriver, isLoading: isUpdatingDriver } =
    useUpdateDriver();
  const { mutateAsync: deleteDriver, isLoading: isDeletingDriver } =
    useDeleteDriver();
  const { mutateAsync: upateDriverLeave } = useUpateDriverLeave();

  const driverList = useMemo(() => {
    return map(driversInfo, ({ user, ...driver }) => {
      const { profileId, id, ...userInfo } = user;

      return {
        ...driver,
        ...userInfo,
      };
    });
  }, [driversInfo]);

  return (
    <DriversComponent
      createDriver={createDriver}
      deleteDriver={deleteDriver}
      driversInfo={driverList}
      pagination={{ ...pagination, total }}
      updateDriver={updateDriver}
      upateDriverLeave={upateDriverLeave}
      isLoading={
        isCreatingDriver ||
        isDeletingDriver ||
        isFetchingDrivers ||
        isUpdatingDriver
      }
    />
  );
}
