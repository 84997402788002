import { ColorPicker, Form, Input, Typography } from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { map } from 'lodash';
import { useEffect } from 'react';
import { LocationSelectorContainer } from '../../shared/location-selector/location-selector.containers';

export function LocationTagForm(props: {
  form: FormInstance;
  data?: {
    id: string;
    name: string;
    description: string;
    locations: {
      name: string;
      idNumber: string;
      id: string;
    }[];
    textColor: string;
    backgroundColor: string;
  };
}) {
  const textColor = useWatch('textColor', props.form);
  const backgroundColor = useWatch('backgroundColor', props.form);

  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form
      form={props.form}
      autoComplete="off"
      initialValues={{
        name: props.data?.name,
        description: props.data?.description,
        locationIds: map(props.data?.locations, 'id'),
        textColor: props.data?.textColor
          ? new ColorFactory(props.data.textColor)
          : new ColorFactory('#000'),
        backgroundColor: props.data?.backgroundColor
          ? new ColorFactory(props.data.backgroundColor)
          : new ColorFactory('#fff'),
      }}
    >
      <Form.Item
        label="名稱"
        name="name"
        rules={[{ required: true, message: '請輸入名稱' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="敘述"
        name="description"
        rules={[{ required: true, message: '請輸入敘述' }]}
      >
        <Input />
      </Form.Item>
      <LocationSelectorContainer multiple={true} />
      <Form.Item label="文字顏色" name="textColor">
        <ColorPicker format="hex" defaultFormat="hex" showText />
      </Form.Item>
      <Form.Item label="背景顏色" name="backgroundColor">
        <ColorPicker format="hex" defaultFormat="hex" showText />
      </Form.Item>
      <Form.Item label="效果預覽" name="previewColor">
        {textColor && backgroundColor && (
          <Typography.Text
            style={{
              color: textColor.toHexString(),
              backgroundColor: backgroundColor.toHexString(),
            }}
          >
            測試文字
          </Typography.Text>
        )}
      </Form.Item>
    </Form>
  );
}
