import { Button, Input, Space, Table, Upload, message } from 'antd';
import useModal from 'antd/es/modal/useModal';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import { UploadChangeParam } from 'antd/es/upload';
import { debounce, isBoolean, map, parseInt } from 'lodash';
import { useQueryClient } from 'react-query';
import { ProductFormComponent } from './product-form.component';
import { useForm } from 'antd/es/form/Form';
import { useAuthStore } from '../utils/stores/useAuthStore';
import { useCallback } from 'react';
import { ProductDto, UpdateProductDto } from '../utils/api-client';
import {
  PRODUCT_STATE,
  PRODUCT_STATE_FILTERS,
  PRODUCT_STATE_LABEL,
} from '../constants/product';

type Props = {
  isLoading: boolean;
  exportProducts: () => void;
  deleteProduct: (id: string) => Promise<void>;
  updateProduct: (data: UpdateProductDto & { id: string }) => Promise<any>;
  products: ProductDto[];
  pagination: TablePaginationConfig;
  setFilters: (data: Record<string, FilterValue | null | boolean>) => void;
  setKeyword: (keyword: string) => void;
  providerFilters: Array<{
    id: string;
    name: string;
  }>;
};

export function ProductsComponent(props: Props) {
  const queryClient = useQueryClient();
  const [form] = useForm<{
    cost: number;
    idNumber: string;
    name: string;
    isToppings: boolean;
    salePrice: number;
    inStorePrice: number;
    state: PRODUCT_STATE;
  }>();
  const [modal, context] = useModal();
  const token = useAuthStore((state) => state.token);

  const onDeleteButtonClick = (product: Props['products'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: `確定要刪除商品 ${product.name} 嗎？`,
      icon: null,
      okText: '刪除',
      title: '刪除商品',
      onOk: async () => {
        return props.deleteProduct(product.id);
      },
    });
  };

  const onEditButtonClick = (product: Props['products'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: <ProductFormComponent data={product} form={form} />,
      icon: null,
      okText: '儲存',
      title: '編輯商品',
      onOk: async () => {
        const value = await form.getFieldsValue();

        return props.updateProduct({
          id: product.id,
          cost: value.cost || null,
          idNumber: value.idNumber,
          inStorePrice: value.inStorePrice || null,
          isToppings: value.isToppings,
          name: value.name,
          salePrice: value.salePrice || null,
          state: value.state,
        });
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onKeywordInputChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      props.setKeyword(e.target.value);
    }, 500),
    [props.setKeyword]
  );

  const columns: ColumnType<Props['products'][number]>[] = [
    {
      align: 'center',
      dataIndex: 'idNumber',
      title: '商品編號',
      width: 80,
    },
    {
      align: 'center',
      dataIndex: 'isToppings',
      filterMultiple: false,
      filters: [
        {
          text: '是',
          value: true,
        },
        {
          text: '否',
          value: false,
        },
      ],
      title: '加項',
      width: 70,
      render: (isToppings) => {
        return isToppings ? '是' : '否';
      },
    },
    {
      align: 'center',
      dataIndex: 'state',
      title: '狀態',
      filters: PRODUCT_STATE_FILTERS,
      width: 80,
      render(state: keyof typeof PRODUCT_STATE) {
        return (
          <span
            className={
              state === 'SUPPLYING' ? 'text-green-500' : 'text-red-500'
            }
          >
            {PRODUCT_STATE_LABEL[state]}
          </span>
        );
      },
    },
    {
      dataIndex: 'providerIds',
      title: '供應商名稱',
      filterSearch: true,
      filters: map(props.providerFilters, (provider) => {
        return {
          text: provider.name,
          value: provider.id,
        };
      }),
      render: (v, record) => {
        return record.provider;
      },
    },
    {
      dataIndex: 'name',
      title: '商品名稱',
    },
    {
      align: 'center',
      dataIndex: 'cost',
      title: '買斷價',
      width: 80,
      render: (cost) => {
        return cost
          ? parseInt(cost, 10).toLocaleString('zh-TW', {
              style: 'currency',
              currency: 'TWD',
              maximumFractionDigits: 0,
            })
          : '未設定';
      },
    },
    {
      align: 'center',
      dataIndex: 'inStorePrice',
      title: '店內價',
      width: 80,
      render: (inStorePrice) => {
        return inStorePrice
          ? parseInt(inStorePrice, 10).toLocaleString('zh-TW', {
              style: 'currency',
              currency: 'TWD',
              maximumFractionDigits: 0,
            })
          : '未設定';
      },
    },
    {
      align: 'center',
      dataIndex: 'salePrice',
      title: '售價',
      width: 80,
      render: (salePrice) => {
        return salePrice
          ? parseInt(salePrice, 10).toLocaleString('zh-TW', {
              style: 'currency',
              currency: 'TWD',
              maximumFractionDigits: 0,
            })
          : '未設定';
      },
    },
    {
      title: '操作',
      width: 130,
      render: (record) => {
        return (
          <Space>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                onEditButtonClick(record);
              }}
            >
              編輯
            </Button>
            <Button
              type="primary"
              size="small"
              danger
              onClick={() => {
                onDeleteButtonClick(record);
              }}
            >
              刪除
            </Button>
          </Space>
        );
      },
    },
  ];

  const onChange = ({ file, fileList }: UploadChangeParam) => {
    if (file.status === 'done') {
      message.success(`上傳成功`);
      queryClient.invalidateQueries(['products']);
    } else if (file.status === 'error') {
      message.error(file.response?.message);
    }
  };

  return (
    <>
      {context}
      <div className="text-2xl font-bold">商品管理</div>
      <Table
        columns={columns}
        dataSource={props.products}
        loading={props.isLoading}
        pagination={props.pagination}
        rowKey="id"
        size="small"
        onChange={(pagination, filters, sorter, extra) => {
          props.setFilters({
            ...filters,
            ...(isBoolean(filters.isToppings?.[0])
              ? { isToppings: filters.isToppings?.[0] || false }
              : {}),
          });
        }}
        title={() => (
          <Space direction="vertical" size="small">
            <Space>
              <Upload
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                action={`${process.env.REACT_APP_API_SERVER_URL}/products/import`}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                name="file"
                onChange={onChange}
                showUploadList={false}
              >
                <Button type="primary">匯入商品</Button>
              </Upload>
              <Button type="primary" onClick={props.exportProducts}>
                匯出商品
              </Button>
              <a href="/商品匯入範本.xlsx">下載商品匯入範本</a>
            </Space>
            <div className="flex items-center gap-2 w-80">
              <span className="shrink-0">關鍵字</span>
              <Input
                className="grow"
                placeholder="請輸商品名稱"
                onChange={onKeywordInputChange}
              />
            </div>
          </Space>
        )}
      />
    </>
  );
}
