export enum USER_STATE {
  ACTIVATED = "ACTIVATED",
  SUSPENDED = "SUSPENDED",
  REVIEWING = "REVIEWING",
}

export const USER_STATE_LABEL = {
  [USER_STATE.ACTIVATED]: "啟用",
  [USER_STATE.SUSPENDED]: "停權",
  [USER_STATE.REVIEWING]: "待審核",
};
