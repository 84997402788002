import { Select, SelectProps } from "antd";
import FormItem from "antd/es/form/FormItem";

type UserSelectorComponentProps = {
  multiple?: boolean;
  options: SelectProps["options"];
  isLoading: boolean;
  required?: boolean;
};

export function UserSelectorComponent(props: UserSelectorComponentProps) {
  return (
    <FormItem
      label='用戶'
      name={props.multiple ? "userIds" : "userId"}
      rules={[{ required: props.required, message: "請選擇用戶" }]}
    >
      <Select
        loading={props.isLoading}
        mode={props.multiple ? "multiple" : undefined}
        optionFilterProp='label'
        options={props.options}
        placeholder='請選擇角色'
      />
    </FormItem>
  );
}
