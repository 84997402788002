import { memo, useCallback, useMemo, useState } from 'react';
import {
  Form,
  Space,
  Badge,
  Select,
  Divider,
  Flex,
  List as AntList,
} from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import FormItem from 'antd/es/form/FormItem';
import type { Dayjs } from 'dayjs';
import { isEmpty, map, reduce, values } from 'lodash';

import 'mapbox-gl/dist/mapbox-gl.css';

import { PROVIDER_STATUS_MAPPING } from '../../constants/schedule-meals.types';
import schedules from '../schedules';

import { CalendarHeader, CalendarCell } from './drivers-calendar.component';
import { ScheduleDriversModal } from './drivers-modal.component';

type ScheduleDriversComponentProps = {
  locationTags: Array<schedules.LocationTag>;
  driverList: {
    id: string;
    idNumber: string;
    name: string;
    address: string;
    phone: string;
    carNumber: string;
    carModel: string;
    carColor: string;
    leaveTimes: number[];
  }[];
  providerTagsInformation: {
    options: { label: string; options: { label: string; value: string }[] }[];
    mappingObject: {
      [key: string]: {
        type: string;
        address: string;
      };
    };
  };
  updateScheduleMeals: Function;
  deleteScheduleMeals: Function;
  updateScheduleMealStatus: Function;
  updateScheduleMealsRank: Function;
  updateScheduleDriver: Function;
  selectDate: Dayjs;
  setSelectDate: Function;
  listData: Record<
    string,
    {
      id: string;
      name: string;
      data: schedules.MappingGroupByTimestamp;
    }
  >;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  colorData: { providerId: string; color: string }[];
};

export function ScheduleDriversComponent({
  listData,
  noteData,
  colorData,
  driverList,
  updateScheduleDriver,
  selectDate,
  setSelectDate,
}: ScheduleDriversComponentProps) {
  const [form] = Form.useForm();

  const [modalInfo, setModalInfo] = useState<{
    open: boolean;
    dateTimestamp?: number;
    locationTagId?: string;
  }>({
    open: false,
  });

  const providerColor = useMemo(() => {
    return reduce(
      colorData,
      (
        accumulate: { [providerId: string]: string },
        item: {
          providerId: string;
          color: string;
        }
      ) => {
        accumulate[item.providerId] = item.color;

        return accumulate;
      },
      {}
    );
  }, [colorData]);

  const renderListData = useCallback(
    (
      cellData: Array<schedules.CellData>,
      timestamp: number,
      source: string
    ) => {
      return (
        <ul style={{ padding: 0 }}>
          {!isEmpty(cellData[timestamp]) &&
            map(
              cellData[timestamp],
              (item: schedules.CellData, index: number) =>
                !isEmpty(item) && (
                  <Flex
                    key={`${item.locationTag.id}.${item.provider.id}.${timestamp}.${item.rank}`}
                    vertical
                  >
                    <Flex component="li" gap="small">
                      <Badge
                        status={
                          item?.status
                            ? (PROVIDER_STATUS_MAPPING[
                                item.status
                              ] as PresetStatusColorType)
                            : 'default'
                        }
                        text={
                          <span
                            className="p-1 block"
                            style={{
                              color: providerColor[item.provider.id],
                              backgroundColor: item.needToRecycleBags
                                ? '#ffe58f'
                                : 'unset',
                            }}
                          >
                            {item.provider?.type}-{item.provider?.name}
                          </span>
                        }
                      />

                      {source !== 'modal' && !isEmpty(item.driver) && (
                        <Flex gap={1} flex="none" align="baseline">
                          <Divider type="vertical" />
                          <span>{`${item.driver?.idNumber} ${item.driver?.name}`}</span>
                        </Flex>
                      )}
                      {source === 'modal' && (
                        <FormItem name={['deliveryMeals', index, 'driverId']}>
                          <Select
                            size="small"
                            allowClear
                            optionFilterProp="label"
                            options={map(driverList, (driver) => ({
                              ...driver,
                              disabled:
                                driver.leaveTimes.indexOf(timestamp) !== -1,
                            }))}
                            placeholder="請選擇司機"
                            showSearch
                          />
                        </FormItem>
                      )}
                    </Flex>
                  </Flex>
                )
            )}
        </ul>
      );
    },
    [driverList, providerColor]
  );

  return (
    <Space direction="vertical" className="w-full page-schedule-meals">
      <Form
        form={form}
        autoComplete="off"
        initialValues={{ deliveryMeals: [] }}
      >
        {modalInfo.open && (
          <ScheduleDriversModal
            modalInfo={modalInfo}
            listData={listData}
            renderListData={renderListData}
            handleModalClose={() => {
              setModalInfo({ open: false });
            }}
            handleModalConfirm={(data: []) => {
              updateScheduleDriver(
                map(form.getFieldValue('deliveryMeals'), (item) => ({
                  id: item.id,
                  driverId: item.driverId || null,
                }))
              );
              setModalInfo({ open: false });
            }}
            form={form}
          />
        )}
      </Form>

      <CalendarHeader
        value={selectDate}
        onChange={(date: Dayjs) => {
          setSelectDate(date);
        }}
      />

      <List
        listData={listData}
        noteData={noteData}
        renderListData={renderListData}
        setModalInfo={setModalInfo}
      />
    </Space>
  );
}

type ListProps = {
  listData: Record<
    string,
    {
      id: string;
      name: string;
      data: schedules.MappingGroupByTimestamp;
    }
  >;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  renderListData: Function;
  setModalInfo: Function;
};

const List = memo(
  ({ listData, noteData, renderListData, setModalInfo }: ListProps) => {
    return (
      <AntList
        size="small"
        itemLayout="horizontal"
        split={false}
        dataSource={values(listData)}
        renderItem={(locationTagItem) => (
          <>
            <Divider />
            <CalendarCell
              key={locationTagItem.id}
              listData={locationTagItem.data}
              locationTagName={locationTagItem.name}
              locationTagId={locationTagItem.id}
              renderListData={renderListData}
              noteData={noteData}
              onClickView={(dateTimestamp: number) => {
                setModalInfo({
                  open: true,
                  dateTimestamp: Number(dateTimestamp),
                  locationTagId: locationTagItem.id,
                });
              }}
            />
          </>
        )}
      />
    );
  }
);
