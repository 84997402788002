import { Form, FormInstance, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect } from "react";

type CancelStatementFormComponentProps = {
  form: FormInstance;
  statement: any;
};

export function CancelStatementFormComponent(
  props: CancelStatementFormComponentProps
) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} initialValues={props.statement}>
      <FormItem label='建立時間'>
        {new Date(props.statement.createdAt).toLocaleString()}
      </FormItem>
      <FormItem label='供應商'>
        {props.statement.provider.idNumber} - {props.statement.provider.name}
      </FormItem>
      <FormItem label='對帳單日期'>
        {new Date(props.statement.startTime).toLocaleDateString()} ~{" "}
        {new Date(props.statement.endTime).toLocaleDateString()}
      </FormItem>
      <FormItem label='金額'>
        {props.statement.amount.toLocaleString("zh-TW", {
          style: "currency",
          currency: "TWD",
          minimumFractionDigits: 0,
        })}
      </FormItem>
      <FormItem label='備註' name='note'>
        <Input.TextArea rows={5} />
      </FormItem>
    </Form>
  );
}
