import { Form, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useEffect } from "react";
import { ProviderSelectorContainer } from "../../shared/provider-selector/provider-selector.containers";

export function ProviderTagForm(props: {
  form: FormInstance;
  data?: {
    id: string;
    name: string;
    description: string;
    providerIds: string[];
  };
}) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete='off' initialValues={props.data}>
      <Form.Item
        label='名稱'
        name='name'
        rules={[{ required: true, message: "請輸入名稱" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='敘述'
        name='description'
        rules={[{ required: true, message: "請輸入敘述" }]}
      >
        <Input />
      </Form.Item>
      <ProviderSelectorContainer multiple={true} />
    </Form>
  );
}
