import { map } from 'lodash';

export enum INVOICE_TYPE {
  INVOICE = 'INVOICE',
  RECEIPT = 'RECEIPT',
}

export enum COMMERCIAL_TERMS {
  BUYOUT = 'BUYOUT',
  COMMISSION = 'COMMISSION',
  GAMBLING = 'GAMBLING',
}

export enum GAMBLING_DISCOUNT_TYPE {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
}

export const GAMBLING_DISCOUNT_TYPE_LABEL = {
  [GAMBLING_DISCOUNT_TYPE.PERCENTAGE]: '百分比',
  [GAMBLING_DISCOUNT_TYPE.FIXED]: '固定金額',
};

export const GAMBLING_DISCOUNT_TYPE_OPTIONS = [
  {
    label: GAMBLING_DISCOUNT_TYPE_LABEL[GAMBLING_DISCOUNT_TYPE.PERCENTAGE],
    value: GAMBLING_DISCOUNT_TYPE.PERCENTAGE,
  },
  {
    label: GAMBLING_DISCOUNT_TYPE_LABEL[GAMBLING_DISCOUNT_TYPE.FIXED],
    value: GAMBLING_DISCOUNT_TYPE.FIXED,
  },
];

export enum PROVIDER_TYPE {
  健康餐 = '健康餐',
  雞肉飯 = '雞肉飯',
  傳統便當 = '傳統便當',
  異國料理 = '異國料理',
  小吃 = '小吃',
  創新便當 = '創新便當',
  粥湯麵鍋物 = '粥湯麵鍋物',
  飲料 = '飲料',
}

export const PROVIDER_TYPE_OPTIONS = map(PROVIDER_TYPE, (value, key) => ({
  label: value,
  value: key,
}));

export const PROVIDER_TYPE_FILTER = map(PROVIDER_TYPE, (value, key) => ({
  text: value,
  value: key,
}));
