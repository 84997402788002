import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { message } from 'antd';
import axios from 'axios';
import { isArrayBuffer } from 'lodash';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 300 * 1000,
      onError: (error: any) => {
        if (isArrayBuffer(error?.response?.data)) {
          const result = JSON.parse(
            new TextDecoder().decode(error?.response?.data)
          );
          message.error(result?.message || error.message, 20);
        } else {
          message.error(error?.response?.data?.message || error.message, 20);
        }
      },
    },
    mutations: {
      onError: (error: any) => {
        message.error(error?.response?.data?.message || error.message, 20);
      },
    },
  },
});

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_URL;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
