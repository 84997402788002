import { Space, Table, TablePaginationConfig } from "antd";
import { ColumnType } from "antd/es/table";

type PermissionsComponentProps = {
  permissions: Array<{
    id: string;
    name: string;
    desc: string;
  }>;
  pagination: TablePaginationConfig;
  isLoading: boolean;
};

export function PermissionsComponent(props: PermissionsComponentProps) {
  const columns: ColumnType<
    PermissionsComponentProps["permissions"][number]
  >[] = [
    {
      title: "名稱",
      dataIndex: "name",
    },
    {
      title: "描述",
      dataIndex: "desc",
    },
  ];

  return (
    <Space direction='vertical' className='w-full'>
      <div className='text-2xl font-bold'>權限列表</div>
      <Table
        columns={columns}
        dataSource={props.permissions}
        loading={props.isLoading}
        pagination={props.pagination}
        rowKey='id'
        size='small'
      />
    </Space>
  );
}
