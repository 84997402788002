import { Button, Input, Space, Table, TablePaginationConfig } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useModal from 'antd/es/modal/useModal';
import { ProviderForm } from './provider-form.component';
import { debounce, map } from 'lodash';
import { useCallback } from 'react';
import {
  COMMERCIAL_TERMS,
  GAMBLING_DISCOUNT_TYPE,
  PROVIDER_TYPE_FILTER,
} from '../constants/provider-form.types';

type Data = {
  address: string | null;
  addressComment: string | null;
  id: string;
  idNumber: string;
  lineGroupId: string;
  name: string;
  nickname: string;
  taxId: string | null;
  type: string | null;
  businessInfo: {
    commercialTerms: COMMERCIAL_TERMS | null;
    commission: number | null;
    gamblingRules: Array<{
      itemSoldQuantityGuarantee: number;
      discountType: GAMBLING_DISCOUNT_TYPE;
      discountAmount: number;
    }> | null;
  } | null;
  paymentInfo: {
    account: string | null;
    accountName: string | null;
    bankName: string | null;
    bankBranchName: string | null;
    receiptType: string | null;
    shouldInvoice: boolean;
  } | null;
  tags: {
    id: string;
    name: string;
  }[];
};

export function ProvidersComponent({
  data,
  isLoading,
  pagination,
  setKeyword,
  createProvider,
  deleteProvider,
  updateProvider,
  setFilters,
}: {
  data: Data[];
  isLoading: boolean;
  pagination?: TablePaginationConfig;
  setKeyword: (keyword: string) => void;
  createProvider: (data: { name: string; lineGroupId: string }) => Promise<any>;
  deleteProvider: (id: string) => Promise<any>;
  setFilters: (filter: Record<string, any>) => void;
  updateProvider: (data: {
    id: string;
    idNumber: string;
    lineGroupId: string;
    name: string;
  }) => Promise<any>;
}) {
  const [modal, contextHolder] = useModal();
  const [form] = useForm();

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <ProviderForm form={form} />,
      icon: null,
      okText: '確認',
      title: '新增供應商',
      width: 640,
      onOk: async () => {
        const values = await form.validateFields();

        return createProvider(values);
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onKeywordChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
    }, 500),
    []
  );

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">供應商列表</div>
      {contextHolder}
      <Table
        dataSource={data}
        loading={isLoading}
        pagination={pagination}
        rowKey="id"
        size="small"
        scroll={{ x: 'max-content' }}
        onChange={(pagination, filters) => {
          setFilters(filters);
        }}
        columns={[
          {
            width: 100,
            title: '編號',
            dataIndex: 'idNumber',
          },
          {
            title: '簡稱',
            width: 150,
            dataIndex: 'nickname',
          },
          {
            title: '類型',
            dataIndex: 'types',
            width: 100,
            filters: PROVIDER_TYPE_FILTER,
            render: (types, record) => {
              return record.type;
            },
          },
          {
            title: '名稱',
            dataIndex: 'name',
          },
          {
            title: '標籤',
            dataIndex: 'tags',
            render: (tags) => {
              return (
                <div className="flex flex-wrap gap-2">
                  {map(tags, (tag) => {
                    return (
                      <div key={tag.id} className="px-2 bg-gray-200 rounded">
                        {tag.name}
                      </div>
                    );
                  })}
                </div>
              );
            },
          },
          {
            title: '操作',
            dataIndex: 'id',
            width: 130,
            render: (id, record) => {
              return (
                <Space>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      modal.confirm({
                        cancelText: '取消',
                        content: <ProviderForm form={form} data={record} />,
                        icon: null,
                        okText: '確認',
                        title: '編輯供應商',
                        width: 640,
                        onOk: async () => {
                          const values = await form.validateFields();

                          return updateProvider({
                            id,
                            ...values,
                          });
                        },
                      });
                    }}
                  >
                    編輯
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    danger
                    onClick={() => {
                      modal.confirm({
                        icon: null,
                        title: '刪除供應商',
                        okText: '確認',
                        cancelText: '取消',
                        content: '確定要刪除供應商嗎？',
                        onOk: async () => {
                          return deleteProvider(id);
                        },
                      });
                    }}
                  >
                    刪除
                  </Button>
                </Space>
              );
            },
          },
        ]}
        title={() => {
          return (
            <div className="flex flex-col items-start gap-2">
              <Button type="primary" onClick={onCreateButtonClick}>
                新增供應商
              </Button>
              <div className="flex items-center gap-4">
                <span className="shrink-0">關鍵字</span>
                <Input
                  placeholder="輸入關鍵字搜尋名稱"
                  onChange={onKeywordChange}
                />
              </div>
            </div>
          );
        }}
      />
    </Space>
  );
}
