import { Button, Calendar, CalendarProps, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDriverLeave } from '../utils/api.hook';

export function LeaveForm(props: {
  form: FormInstance;
  data: {
    id: string;
  };
}) {
  const [selectedValue, setSelectedValue] = useState(new Set<number>([]));
  const [calendarTime, setCalendarTime] = useState(
    dayjs().startOf('month').startOf('day')
  );
  const { data: driverLeave = [] } = useDriverLeave({
    startTime: calendarTime.startOf('month').startOf('day').valueOf(),
    endTime: calendarTime.endOf('month').endOf('day').valueOf(),
    driverIds: [props.data.id],
  });

  const onSelect = (newValue: Dayjs) => {
    const newSelectedValue = cloneDeep(selectedValue);
    const newTimestamp = newValue.startOf('day').valueOf();

    if (newSelectedValue.has(newTimestamp)) {
      newSelectedValue.delete(newTimestamp);
    } else {
      newSelectedValue.add(newTimestamp);
    }

    setSelectedValue(newSelectedValue);
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') {
      if (selectedValue.has(current.startOf('day').valueOf())) {
        return <div className="driver-leave-cell"></div>;
      }

      return <></>;
    }
  };

  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  useEffect(() => {
    // init selected value

    setSelectedValue(
      new Set(
        driverLeave.find((item) => item.driverId === props.data.id)?.times || []
      )
    );
  }, [driverLeave, props.data.id]);

  useEffect(() => {
    // update data for submit

    let allTimestamp = cloneDeep(selectedValue);
    (driverLeave[0]?.times || []).forEach((item) => {
      allTimestamp.add(item);
    });
    let submitData: { driverId: string; time: number; isLeave: boolean }[] = [];

    Array.from(allTimestamp).forEach((time) => {
      if (
        new Set(driverLeave[0]?.times).has(time) !== selectedValue.has(time)
      ) {
        submitData.push({
          driverId: props.data.id,
          time,
          isLeave: selectedValue.has(time),
        });
      }
    });

    props.form.setFieldValue('time', submitData);
  }, [selectedValue, driverLeave, props.data.id, props.form]);

  return (
    <div className="driver-form-container">
      <Form form={props.form} autoComplete="off" initialValues={props.data}>
        <Calendar
          fullscreen={false}
          headerRender={(props) => (
            <CalendarHeader {...props} calendarTime={calendarTime} />
          )}
          cellRender={cellRender}
          onSelect={(props, selectInfo) => {
            if (selectInfo.source === 'date') {
              onSelect(props);
            } else if (selectInfo.source === 'customize') {
              setCalendarTime(props);
            }
          }}
        />
      </Form>
    </div>
  );
}

type CalendarHeaderProps = {
  onChange(date: Dayjs): void;
  calendarTime: Dayjs;
};

export function CalendarHeader({
  onChange,
  calendarTime,
}: CalendarHeaderProps) {
  return (
    <Row gutter={8} justify="space-between" align="middle">
      <Col>
        <Button
          size="small"
          onClick={() => {
            onChange(calendarTime.subtract(1, 'month').startOf('month'));
          }}
        >
          上個月
        </Button>
      </Col>
      <Col>{calendarTime.format('YYYY年MM月')}</Col>
      <Col>
        <Button
          size="small"
          onClick={() => {
            onChange(calendarTime.add(1, 'month').startOf('month'));
          }}
        >
          下個月
        </Button>
      </Col>
    </Row>
  );
}
