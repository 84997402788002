import {
  ColorPicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Space,
} from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';
import { useEffect } from 'react';
import { FunctionInfoComponent } from '../shared/function-info.component';

type LocationFormComponentProps = {
  form: FormInstance;
  location?: {
    name: string;
    idNumber: number;
    backgroundColor: string;
    address: string | null;
    comment: string | null;
    recycleBagsWeight: number;
  };
};

export function LocationFormComponent(props: LocationFormComponentProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form
      form={props.form}
      initialValues={{
        name: props.location?.name,
        idNumber: props.location?.idNumber,
        backgroundColor: props.location?.backgroundColor
          ? new ColorFactory(props.location.backgroundColor)
          : new ColorFactory('#fff'),
        address: props.location?.address,
        comment: props.location?.comment,
        recycleBagsWeight: props.location?.recycleBagsWeight || 0,
      }}
    >
      <Form.Item
        label="名稱"
        name="name"
        rules={[{ required: true, message: '請輸入名稱' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="地址" name="address">
        <Input />
      </Form.Item>
      <Form.Item
        label="編號"
        name="idNumber"
        rules={[
          { required: true, message: '請輸入編號' },
          { type: 'integer', message: '請輸入數字' },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label={
          <Space size="small">
            <span>保溫袋回收權重</span>
            <FunctionInfoComponent title="數字越大越優先讓該路線的司機回收"></FunctionInfoComponent>
          </Space>
        }
        name="recycleBagsWeight"
        rules={[
          { required: true, message: '請輸入保溫袋回收權重' },
          { type: 'integer', message: '請輸入數字' },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="背景顏色"
        name="backgroundColor"
        rules={[
          {
            required: true,
            message: '請輸入背景',
          },
        ]}
      >
        <ColorPicker format="hex" defaultFormat="hex" showText />
      </Form.Item>
      <Form.Item label="備註" name="comment">
        <Input />
      </Form.Item>
    </Form>
  );
}
