import {
  useCreateLocationTag,
  useDeleteLocationTag,
  useLocationTags,
  useUpdateLocationTag,
} from '../../utils/api.hook';
import { LocationsTagsComponent } from './locations-tags.component';

export function LocationsTagsContainer() {
  const { data: { locationTags } = {}, isLoading: isFetchingLocationTags } =
    useLocationTags();
  const { mutateAsync: createLocationTag, isLoading: isCreatingLocationTag } =
    useCreateLocationTag();
  const { mutateAsync: updateLocationTag, isLoading: isUpdatingLocationTag } =
    useUpdateLocationTag();
  const { mutateAsync: deleteLocationTag, isLoading: isDeletingLocationTag } =
    useDeleteLocationTag();

  return (
    <LocationsTagsComponent
      createLocationTag={createLocationTag}
      deleteLocationTag={deleteLocationTag}
      locationTags={locationTags}
      updateLocationTag={updateLocationTag}
      isLoading={
        isCreatingLocationTag ||
        isDeletingLocationTag ||
        isFetchingLocationTags ||
        isUpdatingLocationTag
      }
    />
  );
}
