import { Button, Space, Table } from 'antd';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { debounce, map, omit } from 'lodash';
import { TagComponent } from '../../shared/tag.component';
import { USER_STATE, USER_STATE_LABEL } from '../../constants/user';
import useModal from 'antd/es/modal/useModal';
import { UserFormComponent } from './user-form.component';
import { useForm } from 'antd/es/form/Form';
import { FindUsersResult, UpdateUserDto } from '../../utils/api-client';
import dayjs from 'dayjs';
import Input from 'antd/es/input/Input';
import { useCallback } from 'react';

type Props = {
  approveUser: (id: string) => Promise<any>;
  isLoading: boolean;
  pagination: TablePaginationConfig;
  setFilter: (data: any) => void;
  setKeyword: (data: string) => void;
  suspendUser: (id: string) => Promise<any>;
  unsuspendUser: (id: string) => Promise<any>;
  updateUser: (data: { id: string } & UpdateUserDto) => Promise<any>;
  users: FindUsersResult['users'];
};

export function UserComponent(props: Props) {
  const [model, contextHolder] = useModal();
  const [form] = useForm();

  const onEditButtonClick = (user: Props['users'][number]) => {
    model.confirm({
      cancelText: '取消',
      content: (
        <UserFormComponent
          user={{
            ...omit(user, 'profile'),
            ...(user.profile && {
              profile: {
                ...user.profile,
                birthday: user.profile.birthday
                  ? dayjs(user.profile.birthday)
                  : null,
              },
            }),
            roleIds: map(user.roles, 'id'),
          }}
          form={form}
        />
      ),
      icon: null,
      okText: '儲存',
      title: '編輯用戶',
      onOk: async () => {
        const values = await form.validateFields();

        return props.updateUser({
          id: user.id,
          ...values,
          phone: values.phone || null,
          nationalIdNumber: values.nationalIdNumber || null,
          profile: {
            birthday: values.profile?.birthday || null,
            nickname: values.profile?.nickname || null,
            address: values.profile?.address || null,
            displayName: values.profile?.displayName || null,
          },
        });
      },
    });
  };

  const onUnsuspendButtonClick = async (user: Props['users'][number]) => {
    model.confirm({
      title: '解除停權',
      icon: null,
      content: `確定要解除停權用戶 ${user.displayName} 嗎？`,
      okText: '確定',
      cancelText: '取消',
      onOk: () => {
        return props.unsuspendUser(user.id);
      },
    });
  };

  const onSuspendButtonClick = async (user: Props['users'][number]) => {
    model.confirm({
      title: '停權',
      icon: null,
      content: `確定要停權用戶 ${user.displayName} 嗎？`,
      okText: '確定',
      cancelText: '取消',
      onOk: () => {
        return props.suspendUser(user.id);
      },
    });
  };

  const onApproveButtonClick = async (user: Props['users'][number]) => {
    model.confirm({
      title: '啟用',
      icon: null,
      content: (
        <div>
          <div>確定要將用戶 {user.displayName} 狀態轉為啟用嗎？</div>
          <div>帳號：{user.username || '尚未設定'}</div>
          <div>暱稱：{user.profile?.nickname || '尚未設定'}</div>
          <div>電話：{user.phone || '尚未設定'}</div>
          <div>身分證字號：{user.nationalIdNumber || '尚未設定'}</div>
          <div>角色：{map(user.roles, 'name').join(', ')}</div>
        </div>
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: () => {
        return props.approveUser(user.id);
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onKeywordInputChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      props.setKeyword(e.target.value);
    }, 500),
    [props.setKeyword]
  );

  const columns: ColumnType<Props['users'][number]>[] = [
    {
      align: 'center',
      dataIndex: 'state',
      title: '狀態',
      filters: [
        { text: '啟用', value: USER_STATE.ACTIVATED },
        { text: '審核中', value: USER_STATE.REVIEWING },
        { text: '停權', value: USER_STATE.SUSPENDED },
      ],
      width: 100,
      render: (state: USER_STATE) => {
        return (
          <span
            className={`${state === 'SUSPENDED' && 'text-red-500'} ${state === USER_STATE.REVIEWING && 'text-blue-500'}`}
          >
            {USER_STATE_LABEL[state]}
          </span>
        );
      },
    },
    {
      dataIndex: 'displayName',
      title: '顯示名稱',
      width: 120,
    },
    {
      dataIndex: ['profile', 'nickname'],
      title: '暱稱',
      width: 120,
    },
    {
      dataIndex: 'username',
      title: '帳號',
      width: 120,
      render: (username) => {
        return username || '尚未設定';
      },
    },
    {
      dataIndex: 'phone',
      title: '電話號碼',
      width: 100,
      render: (phone) => {
        return phone || '尚未設定';
      },
    },
    {
      dataIndex: 'roles',
      title: '角色',
      render: (roles) => {
        return (
          <div className="flex flex-wrap gap-2">
            {map(roles, (role) => (
              <TagComponent title={role.name} key={role.name} />
            ))}
          </div>
        );
      },
    },
    {
      title: '操作',
      width: 150,
      render: (user) => {
        return (
          <Space size="small">
            <Button
              disabled={user.isProtected}
              onClick={() => onEditButtonClick(user)}
              size="small"
              type="primary"
            >
              編輯
            </Button>
            {user.state === USER_STATE.REVIEWING && (
              <Button
                size="small"
                type="primary"
                ghost
                onClick={() => onApproveButtonClick(user)}
              >
                啟用
              </Button>
            )}
            {user.state === 'SUSPENDED' ? (
              <>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => onUnsuspendButtonClick(user)}
                >
                  解除停權
                </Button>
                <Button
                  danger
                  disabled={user.isProtected}
                  size="small"
                  type="primary"
                >
                  刪除
                </Button>
              </>
            ) : (
              <Button
                danger
                disabled={user.isProtected}
                size="small"
                type="primary"
                onClick={() => onSuspendButtonClick(user)}
              >
                停權
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Space size="small" direction="vertical" className="w-full">
      {contextHolder}
      <div className="text-2xl font-bold">用戶管理</div>
      <Table
        columns={columns}
        dataSource={props.users}
        loading={props.isLoading}
        pagination={props.pagination}
        rowKey="id"
        size="small"
        onChange={(_page, filter) => {
          props.setFilter(filter);
        }}
        title={() => {
          return (
            <div className="flex items-center gap-2 w-80">
              <span className="shrink-0">關鍵字</span>
              <Input
                className="grow"
                placeholder="請輸顯示名稱、暱稱或是電話號碼"
                onChange={onKeywordInputChange}
              />
            </div>
          );
        }}
      />
    </Space>
  );
}
