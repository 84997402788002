import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
} from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { useEffect } from "react";
import { PROVIDER_EVENT_TYPE_OPTIONS } from "../../constants/provider-event.types";
import { ProviderSelectorContainer } from "../../shared/provider-selector/provider-selector.containers";
import dayjs from "dayjs";

type Props = {
  form: FormInstance;
  data?: {
    providerId: string;
    type: string;
    fine: number;
    time: string;
    note: string | null;
  };
};

export function ProviderEventFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form
      form={props.form}
      initialValues={{
        ...props.data,
        time: props.data?.time ? dayjs(props.data?.time) : undefined,
      }}
    >
      <Form.Item
        label='時間'
        name='time'
        rules={[{ required: true, message: "請選擇時間" }]}
      >
        <DatePicker locale={locale} />
      </Form.Item>
      <ProviderSelectorContainer
        isRequired
        isDisabled={!!props.data?.providerId}
      />
      <Form.Item
        label='供應商事件類型'
        name='type'
        rules={[{ required: true, message: "請選擇供應商事件" }]}
      >
        <Select
          placeholder='請選擇供應商事件'
          options={PROVIDER_EVENT_TYPE_OPTIONS}
        />
      </Form.Item>
      <Form.Item
        label='罰款金額'
        name='fine'
        rules={[
          { required: true, message: "請輸入罰款金額" },
          { type: "number", min: 1, message: "罰款金額必須大於 0" },
        ]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item label='備註' name='note'>
        <Input />
      </Form.Item>
    </Form>
  );
}
