import { Form, FormInstance, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect } from "react";

type Props = {
  statement: {
    note: string | null;
  };
  form: FormInstance;
};

export function StatementFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} initialValues={props.statement}>
      <FormItem label='備註' name='note'>
        <Input.TextArea rows={5} />
      </FormItem>
    </Form>
  );
}
