import { Button, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import useModal from "antd/es/modal/useModal";
import { ColumnType, TablePaginationConfig } from "antd/es/table";
import { ProviderEventFormComponent } from "./provider-event-form.component";
import {
  PROVIDER_EVENT_TYPE_LABELS,
  PROVIDER_EVENT_TYPES,
} from "../../constants/provider-event.types";
import { map } from "lodash";
import { FilterValue } from "antd/es/table/interface";

type Props = {
  deleteProviderEvent: (id: string) => Promise<void>;
  isLoading: boolean;
  pagination: TablePaginationConfig;
  setFilters: (filters: Record<string, FilterValue>) => void;
  timeFilters: Array<string>;
  events: Array<{
    id: string;
    time: string;
    providerId: string;
    type: PROVIDER_EVENT_TYPES;
    fine: number;
    note: string | null;
    provider: {
      id: string;
      name: string;
      idNumber: string;
    };
  }>;
  providerFilters: Array<{
    id: string;
    idNumber: string;
    name: string;
  }>;
  createProviderEvent: (data: {
    providerId: string;
    fine: number;
    type: PROVIDER_EVENT_TYPES;
    time: string;
    note: string | null;
  }) => Promise<void>;
  updateProviderEvent: (data: {
    id: string;
    fine: number;
    type: PROVIDER_EVENT_TYPES;
    time: string;
    note: string | null;
  }) => Promise<void>;
};

export function ProviderEventsComponent(props: Props) {
  const [modal, contextHolder] = useModal();
  const [form] = useForm();

  const onDeleteButtonClick = (data: Props["events"][number]) => {
    modal.confirm({
      icon: null,
      title: "刪除供應商事件",
      okText: "刪除",
      cancelText: "取消",
      okButtonProps: {
        danger: true,
      },
      content: (
        <div className='flex flex-col'>
          <div>
            <span>時間：</span>
            <span>{new Date(data.time).toLocaleDateString()}</span>
          </div>
          <div>
            <span>供應商：</span>
            <span>{data.provider.name}</span>
          </div>
          <div>
            <span>事件：</span>
            <span>{PROVIDER_EVENT_TYPE_LABELS[data.type]}</span>
          </div>
          <div>
            <span>罰款金額：</span>
            <span>{data.fine}</span>
          </div>
          <div>
            <span>備註：</span>
            <span>{data.note}</span>
          </div>
        </div>
      ),
      onOk: async () => {
        return props.deleteProviderEvent(data.id);
      },
    });
  };

  const onEditButtonClick = (data: Props["events"][number]) => {
    modal.confirm({
      icon: null,
      title: "編輯供應商事件",
      content: <ProviderEventFormComponent form={form} data={data} />,
      okText: "儲存",
      cancelText: "取消",
      onOk: async () => {
        const values = await form.validateFields();

        return props.updateProviderEvent({
          id: data.id,
          fine: values.fine,
          type: values.type,
          time: values.time,
          note: values.note,
        });
      },
    });
  };

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <ProviderEventFormComponent form={form} />,
      icon: null,
      okText: "新增",
      title: "新增供應商事件",
      onOk: async () => {
        const values = await form.validateFields();

        await props.createProviderEvent({
          providerId: values.providerId,
          fine: values.fine,
          type: values.type,
          time: values.time,
          note: values.note,
        });
      },
    });
  };

  const columns: ColumnType<Props["events"][number]>[] = [
    {
      dataIndex: "time",
      render: (time: string) => new Date(time).toLocaleDateString(),
      title: "時間",
      width: 100,
    },
    {
      title: "供應商",
      dataIndex: "provider",
      filterSearch: true,
      filters: map(props.providerFilters, (provider) => ({
        text: `${provider.idNumber} - ${provider.name}`,
        value: provider.id,
      })),
      render(provider) {
        return `${provider.idNumber} - ${provider.name}`;
      },
    },
    {
      title: "事件",
      dataIndex: "type",
      render: (type: PROVIDER_EVENT_TYPES) => PROVIDER_EVENT_TYPE_LABELS[type],
    },
    {
      title: "罰款金額",
      width: 100,
      dataIndex: "fine",
    },
    {
      title: "備註",
      dataIndex: "note",
    },
    {
      title: "操作",
      width: 150,
      render: (record) => (
        <Space>
          <Button
            type='primary'
            size='small'
            onClick={() => {
              onEditButtonClick(record);
            }}
          >
            編輯
          </Button>
          <Button
            type='primary'
            danger
            size='small'
            onClick={() => {
              onDeleteButtonClick(record);
            }}
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='flex flex-col gap-4'>
      {contextHolder}
      <span className='text-2xl font-bold'>供應商事件管理</span>
      <Table
        columns={columns}
        dataSource={props.events}
        loading={props.isLoading}
        pagination={props.pagination}
        rowKey='id'
        size='small'
        onChange={(pagination, filters) => {
          props.setFilters({
            ...filters,
            ...(filters["provider"] && {
              providerIds: filters["provider"],
            }),
          });
        }}
        title={() => (
          <Button type='primary' onClick={onCreateButtonClick}>
            新增供應商事件
          </Button>
        )}
      />
    </div>
  );
}
