import { Checkbox } from "antd";
import FormItem from "antd/es/form/FormItem";
import { map } from "lodash";
import { useMemo } from "react";
import { LOCATION_NUMBER } from "../../constants/location-number";

type LocationCheckGroupComponentProps = {
  locations: {
    id: string;
    idNumber: number;
    name: string;
  }[];
};

export function LocationCheckGroupComponent(
  props: LocationCheckGroupComponentProps
) {
  const options = useMemo(() => {
    return map(props.locations, (location) => {
      const label = `${LOCATION_NUMBER[location.idNumber.toString()]} ${
        location.name
      }`;
      return {
        label,
        value: location.id,
      };
    });
  }, [props.locations]);

  return (
    <FormItem
      label='大樓'
      name='locationIds'
      rules={[{ required: true, message: "請選擇大樓" }]}
    >
      <Checkbox.Group options={options} />
    </FormItem>
  );
}
