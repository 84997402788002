import axios from 'axios';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_PUBLIC_KEY || '';
const GEOCODE_URL = process.env.REACT_APP_MAPBOX_API_GEOCODE_URL || '';
const DRIVING_URL = process.env.REACT_APP_MAPBOX_API_DRIVING_URL || '';

export function useMapGeocoding() {
  return useMutation(['mapGeocoding'], async (data: { keyword: string }) => {
    const response = await axios.get(GEOCODE_URL, {
      params: {
        q: data.keyword,
        access_token: ACCESS_TOKEN,
      },
    });
    return response.data;
  });
}

export async function getMapDirection(params: string[]) {
  const path = params.join('%3B');
  const response = await axios.get(`${DRIVING_URL}/${path}`, {
    params: {
      alternatives: true,
      geometries: 'geojson',
      language: 'tw',
      overview: 'full',
      steps: false,
      access_token: ACCESS_TOKEN,
      depart_at: dayjs().hour(11).minute(30).format('YYYY-MM-DDTHH:mm'),
    },
  });
  return response.data;
}
