import { DatePicker, Form, FormInstance, Input } from 'antd';
import { RoleSelectorContainer } from '../../shared/role-selector/role-selector.container';
import { useEffect } from 'react';
import { ProfileDto, UserDto } from '../../utils/api-client';
import locale from 'antd/es/date-picker/locale/zh_TW';
import { Dayjs } from 'dayjs';

type Props = {
  form: FormInstance;
  user?: Omit<UserDto, 'profile'> & {
    profile?:
      | (Omit<ProfileDto, 'birthday'> & {
          birthday: Dayjs | null;
        })
      | null;
    roleIds: string[];
  };
};

export function UserFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} initialValues={props.user || {}}>
      <Form.Item label="帳號" name="username">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="顯示名稱"
        name={['profile', 'displayName']}
        rules={[{ required: true, message: '請輸入顯示名稱' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="暱稱"
        name={['profile', 'nickname']}
        rules={[{ required: true, message: '請輸入暱稱' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="生日" name={['profile', 'birthday']}>
        <DatePicker locale={locale} />
      </Form.Item>
      <Form.Item
        label="身分證字號"
        name="nationalIdNumber"
        rules={[
          {
            type: 'string',
            message: '請輸入有效的身分證字號',
          },
          {
            validator: async (rule, value) => {
              if (value && !/^[A-Z]\d{9}$/.test(value)) {
                throw new Error('請輸入有效的身分證字號: A123456789');
              }
            },
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        label="電話號碼"
        name="phone"
        rules={[
          {
            type: 'string',
            message: '請輸入有效的電話號碼',
          },
          {
            validator: async (rule, value) => {
              if (value && !/^09\d{8}$/.test(value)) {
                throw new Error('請輸入有效的電話號碼: 09xxxxxxxx');
              }
            },
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item label="居住區" name={['profile', 'address']}>
        <Input />
      </Form.Item>
      <RoleSelectorContainer multiple required />
    </Form>
  );
}
