import { DatePicker, Form, FormInstance } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import locale from "antd/es/date-picker/locale/zh_TW";
import { LocationCheckGroupContainer } from "../shared/location-check-group/location-check-group.container";

type ExportPosDataComponentProps = {
  form: FormInstance;
};

export function ExportPosDataComponent(props: ExportPosDataComponentProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form
      form={props.form}
      initialValues={{
        date: dayjs().startOf("day"),
        locationIds: [],
      }}
    >
      <Form.Item>
        <div>請選擇要匯出的取貨日期。</div>
        <div className='text-red-400'>
          匯出可能需要 1 到 2 分鐘，請不要關閉瀏覽器。
        </div>
      </Form.Item>
      <Form.Item
        label='日期'
        name='date'
        rules={[{ required: true, message: "請選擇日期" }]}
      >
        <DatePicker locale={locale} />
      </Form.Item>
      <LocationCheckGroupContainer />
    </Form>
  );
}
