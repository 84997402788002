import {
  useApproveUser,
  useSuspendUser,
  useUnsuspendUser,
  useUpdateUser,
  useUsers,
} from '../../utils/api.hook';
import { usePagination } from '../../shared/usePagintation';
import { UserComponent } from './users.component';
import { useState } from 'react';

export function UsersContainer() {
  const pagination = usePagination();
  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = useState({});
  const {
    data: { users = [], total = 0 } = {},
    isFetching,
    isLoading,
  } = useUsers({
    ...filter,
    keyword,
    take: pagination.take,
    skip: pagination.skip,
  });
  const { mutateAsync: suspendUser, isLoading: isSuspendLoading } =
    useSuspendUser();
  const { mutateAsync: unsuspendUser, isLoading: isUnsuspendLoading } =
    useUnsuspendUser();
  const { mutateAsync: updateUser, isLoading: isUpdateLoading } =
    useUpdateUser();
  const { mutateAsync: approveUser, isLoading: isApproveLoading } =
    useApproveUser();

  return (
    <UserComponent
      approveUser={approveUser}
      setFilter={setFilter}
      setKeyword={setKeyword}
      suspendUser={suspendUser}
      unsuspendUser={unsuspendUser}
      updateUser={updateUser}
      users={users || []}
      isLoading={
        isLoading ||
        isFetching ||
        isSuspendLoading ||
        isUnsuspendLoading ||
        isUpdateLoading ||
        isApproveLoading
      }
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
