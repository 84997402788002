import { Form, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import "dayjs/locale/zh-tw";
import { FormInstance } from "antd/es/form/Form";
import { useEffect } from "react";
import dayjs from "dayjs";

type Props = {
  form: FormInstance;
};

export function ExportOrdersFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form
      form={props.form}
      initialValues={{
        timeRange: [dayjs().startOf("month"), dayjs().endOf("month")],
      }}
    >
      <Form.Item>
        <div>請選擇要匯出的取貨日期。</div>
        <div className='text-red-400'>
          匯出可能需要 1 到 2 分鐘，請不要關閉瀏覽器。
        </div>
      </Form.Item>
      <Form.Item
        label='日期'
        name='timeRange'
        rules={[{ required: true, message: "請選擇日期" }]}
      >
        <DatePicker.RangePicker locale={locale} />
      </Form.Item>
    </Form>
  );
}
