export enum REFUND_REASON {
  // 餐點品質不佳
  QUALITY_PROBLEM = "QUALITY_PROBLEM",
  // 餐點有異物
  FOREIGN_OBJECT = "FOREIGN_OBJECT",
  // 內容物有缺漏
  MISSING_CONTENT = "MISSING_CONTENT",
  // 臨時變更菜色
  TEMPORARY_CHANGE = "TEMPORARY_CHANGE",
  // 產品內容物與標示不符
  CONTENT_LABEL_ERROR = "CONTENT_LABEL_ERROR",
  OTHER = "OTHER",
}

export const REFUND_REASON_LABEL = {
  [REFUND_REASON.QUALITY_PROBLEM]: "餐點品質不佳",
  [REFUND_REASON.FOREIGN_OBJECT]: "餐點有異物",
  [REFUND_REASON.MISSING_CONTENT]: "內容物有缺漏",
  [REFUND_REASON.TEMPORARY_CHANGE]: "臨時變更菜色",
  [REFUND_REASON.CONTENT_LABEL_ERROR]: "產品內容物與標示不符",
  [REFUND_REASON.OTHER]: "其他",
};

export const REFUND_REASON_OPTIONS = [
  {
    label: REFUND_REASON_LABEL[REFUND_REASON.QUALITY_PROBLEM],
    value: REFUND_REASON.QUALITY_PROBLEM,
  },
  {
    label: REFUND_REASON_LABEL[REFUND_REASON.FOREIGN_OBJECT],
    value: REFUND_REASON.FOREIGN_OBJECT,
  },
  {
    label: REFUND_REASON_LABEL[REFUND_REASON.MISSING_CONTENT],
    value: REFUND_REASON.MISSING_CONTENT,
  },
  {
    label: REFUND_REASON_LABEL[REFUND_REASON.TEMPORARY_CHANGE],
    value: REFUND_REASON.TEMPORARY_CHANGE,
  },
  {
    label: REFUND_REASON_LABEL[REFUND_REASON.CONTENT_LABEL_ERROR],
    value: REFUND_REASON.CONTENT_LABEL_ERROR,
  },
  {
    label: REFUND_REASON_LABEL[REFUND_REASON.OTHER],
    value: REFUND_REASON.OTHER,
  },
];
