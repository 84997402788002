/* tslint:disable */
/* eslint-disable */
/**
 * Simply Carbs API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CancelStatementDto
 */
export interface CancelStatementDto {
    /**
     * 
     * @type {string}
     * @memberof CancelStatementDto
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface CreateDeliveryMealElement
 */
export interface CreateDeliveryMealElement {
    /**
     * This is milliseconds timestamp
     * @type {number}
     * @memberof CreateDeliveryMealElement
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryMealElement
     */
    'locationTagId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryMealElement
     */
    'providerId': string;
}
/**
 * 
 * @export
 * @interface CreateDeliveryMealsDto
 */
export interface CreateDeliveryMealsDto {
    /**
     * 
     * @type {Array<CreateDeliveryMealElement>}
     * @memberof CreateDeliveryMealsDto
     */
    'deliveryMeals': Array<CreateDeliveryMealElement>;
}
/**
 * 
 * @export
 * @interface CreateDriverInfoDto
 */
export interface CreateDriverInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDriverInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDriverInfoDto
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDriverInfoDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDriverInfoDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDriverInfoDto
     */
    'carNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDriverInfoDto
     */
    'carModel'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDriverInfoDto
     */
    'carColor'?: string;
}
/**
 * 
 * @export
 * @interface CreateLocationDto
 */
export interface CreateLocationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateLocationDto
     */
    'idNumber': number;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    'backgroundColor': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface CreateLocationTagDto
 */
export interface CreateLocationTagDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLocationTagDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationTagDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationTagDto
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationTagDto
     */
    'textColor'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLocationTagDto
     */
    'locationIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateProviderDto
 */
export interface CreateProviderDto {
    /**
     * The name of the Provider
     * @type {string}
     * @memberof CreateProviderDto
     */
    'name': string;
    /**
     * The id number of the Provider
     * @type {string}
     * @memberof CreateProviderDto
     */
    'idNumber': string;
    /**
     * The line group id of the Provider
     * @type {string}
     * @memberof CreateProviderDto
     */
    'lineGroupId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'taxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderDto
     */
    'foodBusinessRegistrationNumber'?: string;
}
/**
 * 
 * @export
 * @interface CreateProviderEventDto
 */
export interface CreateProviderEventDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderEventDto
     */
    'providerId': string;
    /**
     * 
     * @type {object}
     * @memberof CreateProviderEventDto
     */
    'type': object;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderEventDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderEventDto
     */
    'time': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProviderEventDto
     */
    'fine': number;
}
/**
 * 
 * @export
 * @interface CreateProviderTagDto
 */
export interface CreateProviderTagDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProviderTagDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProviderTagDto
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateProviderTagDto
     */
    'providerIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'desc'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleDto
     */
    'userIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleDto
     */
    'permissionIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteDeliveryMealsDto
 */
export interface DeleteDeliveryMealsDto {
    /**
     * 
     * @type {Array<CreateDeliveryMealElement>}
     * @memberof DeleteDeliveryMealsDto
     */
    'deliveryMeals': Array<CreateDeliveryMealElement>;
}
/**
 * 
 * @export
 * @interface ExportDeliveryMealsDto
 */
export interface ExportDeliveryMealsDto {
    /**
     * This is milliseconds timestamp
     * @type {number}
     * @memberof ExportDeliveryMealsDto
     */
    'startTime': number;
    /**
     * This is milliseconds timestamp
     * @type {number}
     * @memberof ExportDeliveryMealsDto
     */
    'endTime': number;
}
/**
 * 
 * @export
 * @interface ExportStatementsDto
 */
export interface ExportStatementsDto {
    /**
     * 
     * @type {string}
     * @memberof ExportStatementsDto
     */
    'month': string;
    /**
     * 
     * @type {string}
     * @memberof ExportStatementsDto
     */
    'period': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportStatementsDto
     */
    'providerIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FindProductsResult
 */
export interface FindProductsResult {
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof FindProductsResult
     */
    'products': Array<ProductDto>;
    /**
     * 
     * @type {Array<ProviderFilterDto>}
     * @memberof FindProductsResult
     */
    'providerFilters': Array<ProviderFilterDto>;
    /**
     * 
     * @type {number}
     * @memberof FindProductsResult
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface FindUsersResult
 */
export interface FindUsersResult {
    /**
     * User IDs
     * @type {Array<UserDto>}
     * @memberof FindUsersResult
     */
    'users': Array<UserDto>;
    /**
     * 
     * @type {number}
     * @memberof FindUsersResult
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface GetMyProfileResult
 */
export interface GetMyProfileResult {
    /**
     * 
     * @type {UserProfileDto}
     * @memberof GetMyProfileResult
     */
    'user': UserProfileDto | null;
}
/**
 * 
 * @export
 * @interface LineLoginDto
 */
export interface LineLoginDto {
    /**
     * Line access token
     * @type {string}
     * @memberof LineLoginDto
     */
    'lineAccessToken': string;
}
/**
 * 
 * @export
 * @interface LocationAssistantInfoDto
 */
export interface LocationAssistantInfoDto {
    /**
     * 
     * @type {string}
     * @memberof LocationAssistantInfoDto
     */
    'transportationMethod': string | null;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface PayStatementsDto
 */
export interface PayStatementsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PayStatementsDto
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {object}
     * @memberof ProductDto
     */
    'cost': object;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'isToppings': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'provider': string;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'birthday'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'nickname'?: string | null;
}
/**
 * 
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'displayName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'nickname': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'birthday': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDto
     */
    'photoUrl': string | null;
}
/**
 * 
 * @export
 * @interface ProviderFilterDto
 */
export interface ProviderFilterDto {
    /**
     * 
     * @type {string}
     * @memberof ProviderFilterDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProviderFilterDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RefundOrderItemDto
 */
export interface RefundOrderItemDto {
    /**
     * 
     * @type {number}
     * @memberof RefundOrderItemDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof RefundOrderItemDto
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof RefundOrderItemDto
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof RefundOrderItemDto
     */
    'fee': number;
}
/**
 * 
 * @export
 * @interface ResolveStatementDto
 */
export interface ResolveStatementDto {
    /**
     * 
     * @type {string}
     * @memberof ResolveStatementDto
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'desc': string | null;
}
/**
 * 
 * @export
 * @interface SendConfirmNotificationDto
 */
export interface SendConfirmNotificationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendConfirmNotificationDto
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface SendMenuDto
 */
export interface SendMenuDto {
    /**
     * 
     * @type {string}
     * @memberof SendMenuDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof SendMenuDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof SendMenuDto
     */
    'type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendMenuDto
     */
    'providerIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryMealRanksDto
 */
export interface UpdateDeliveryMealRanksDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryMealRanksDto
     */
    'locationTagId': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryMealRanksDto
     */
    'time': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDeliveryMealRanksDto
     */
    'deliveryMealIds': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryMealStatusDto
 */
export interface UpdateDeliveryMealStatusDto {
    /**
     * 
     * @type {object}
     * @memberof UpdateDeliveryMealStatusDto
     */
    'status': object;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryMealsRecycleBagsDto
 */
export interface UpdateDeliveryMealsRecycleBagsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryMealsRecycleBagsDto
     */
    'time'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDriverElement
 */
export interface UpdateDriverElement {
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverElement
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof UpdateDriverElement
     */
    'driverId': object;
}
/**
 * 
 * @export
 * @interface UpdateDriverInfoDto
 */
export interface UpdateDriverInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverInfoDto
     */
    'idNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverInfoDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverInfoDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverInfoDto
     */
    'carNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverInfoDto
     */
    'carModel'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverInfoDto
     */
    'carColor'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDriversDto
 */
export interface UpdateDriversDto {
    /**
     * 
     * @type {Array<UpdateDriverElement>}
     * @memberof UpdateDriversDto
     */
    'deliveryMeals': Array<UpdateDriverElement>;
}
/**
 * 
 * @export
 * @interface UpdateIgnoredToppingsDto
 */
export interface UpdateIgnoredToppingsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateIgnoredToppingsDto
     */
    'toppings': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateLocationDto
 */
export interface UpdateLocationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationDto
     */
    'idNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface UpdateLocationTagDto
 */
export interface UpdateLocationTagDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationTagDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationTagDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationTagDto
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationTagDto
     */
    'textColor'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLocationTagDto
     */
    'locationIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateMyProfileDto
 */
export interface UpdateMyProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileDto
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileDto
     */
    'birthday': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyProfileDto
     */
    'photoUrl'?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateMyProfileDto
     */
    'transportationMethod': object;
}
/**
 * 
 * @export
 * @interface UpdateOrderItemDto
 */
export interface UpdateOrderItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderItemDto
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProductDto
 */
export interface UpdateProductDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'idNumber': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'cost'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProductDto
     */
    'isToppings': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'salePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProductDto
     */
    'inStorePrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductDto
     */
    'state': UpdateProductDtoStateEnum;
}

export const UpdateProductDtoStateEnum = {
    Supplying: 'SUPPLYING',
    Discontinued: 'DISCONTINUED'
} as const;

export type UpdateProductDtoStateEnum = typeof UpdateProductDtoStateEnum[keyof typeof UpdateProductDtoStateEnum];

/**
 * 
 * @export
 * @interface UpdateProviderDto
 */
export interface UpdateProviderDto {
    /**
     * The name of the Provider
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'name'?: string;
    /**
     * The id number of the Provider
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'idNumber'?: string;
    /**
     * The line group id of the Provider
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'lineGroupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'taxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderDto
     */
    'foodBusinessRegistrationNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProviderEventDto
 */
export interface UpdateProviderEventDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderEventDto
     */
    'providerId'?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateProviderEventDto
     */
    'type'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderEventDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderEventDto
     */
    'time'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProviderEventDto
     */
    'fine'?: number;
}
/**
 * 
 * @export
 * @interface UpdateProviderTagDto
 */
export interface UpdateProviderTagDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderTagDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProviderTagDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProviderTagDto
     */
    'providerIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateStatementDto
 */
export interface UpdateStatementDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateStatementDto
     */
    'note'?: string;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'nationalIdNumber'?: string | null;
    /**
     * 
     * @type {Profile}
     * @memberof UpdateUserDto
     */
    'profile': Profile;
}
/**
 * 
 * @export
 * @interface UpsertDeliveryMealNoteDto
 */
export interface UpsertDeliveryMealNoteDto {
    /**
     * 
     * @type {number}
     * @memberof UpsertDeliveryMealNoteDto
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof UpsertDeliveryMealNoteDto
     */
    'locationTagId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertDeliveryMealNoteDto
     */
    'note': string;
}
/**
 * 
 * @export
 * @interface UpsertProviderColorDto
 */
export interface UpsertProviderColorDto {
    /**
     * 
     * @type {string}
     * @memberof UpsertProviderColorDto
     */
    'providerId': string;
    /**
     * 
     * @type {string}
     * @memberof UpsertProviderColorDto
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'username': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'nationalIdNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'displayName': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'state': string;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof UserDto
     */
    'roles': Array<RoleDto>;
    /**
     * 
     * @type {ProfileDto}
     * @memberof UserDto
     */
    'profile': ProfileDto | null;
    /**
     * 
     * @type {LocationAssistantInfoDto}
     * @memberof UserDto
     */
    'locationAssistantInfo': LocationAssistantInfoDto | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phone': string | null;
}
/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'username': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'nationalIdNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'displayName': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'state': string;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof UserProfileDto
     */
    'roles': Array<RoleDto>;
    /**
     * 
     * @type {ProfileDto}
     * @memberof UserProfileDto
     */
    'profile': ProfileDto | null;
    /**
     * 
     * @type {LocationAssistantInfoDto}
     * @memberof UserProfileDto
     */
    'locationAssistantInfo': LocationAssistantInfoDto | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'phone': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    'isCompleteProfile': boolean;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGoogle: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGoogleCallback: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/google/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LineLoginDto} lineLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLineLogin: async (lineLoginDto: LineLoginDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lineLoginDto' is not null or undefined
            assertParamExists('authControllerLineLogin', 'lineLoginDto', lineLoginDto)
            const localVarPath = `/auth/line/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGoogle(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGoogle(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerGoogle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGoogleCallback(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGoogleCallback(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerGoogleCallback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LineLoginDto} lineLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLineLogin(lineLoginDto: LineLoginDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLineLogin(lineLoginDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerLineLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGoogle(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerGoogle(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGoogleCallback(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerGoogleCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LineLoginDto} lineLoginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLineLogin(lineLoginDto: LineLoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerLineLogin(lineLoginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerLogin(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGoogle(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerGoogle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGoogleCallback(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerGoogleCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LineLoginDto} lineLoginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLineLogin(lineLoginDto: LineLoginDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLineLogin(lineLoginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lineBotControllerGetGroupJoinedLog: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/line_bot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerNames] 
         * @param {Array<string>} [times] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemRefundControllerFindAll: async (skip?: number, take?: number, providerNames?: Array<string>, times?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/order_item_refunds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (providerNames) {
                localVarQueryParameter['providerNames'] = providerNames;
            }

            if (times) {
                localVarQueryParameter['times'] = times;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProviderEventDto} createProviderEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerCreateProviderEvent: async (createProviderEventDto: CreateProviderEventDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderEventDto' is not null or undefined
            assertParamExists('providerEventControllerCreateProviderEvent', 'createProviderEventDto', createProviderEventDto)
            const localVarPath = `/provider_events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerDeleteProviderEvent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerEventControllerDeleteProviderEvent', 'id', id)
            const localVarPath = `/provider_events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [times] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerFindProviderEvents: async (skip?: number, take?: number, providerIds?: Array<string>, times?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider_events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds;
            }

            if (times) {
                localVarQueryParameter['times'] = times;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderEventDto} updateProviderEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerUpdateProviderEvent: async (id: string, updateProviderEventDto: UpdateProviderEventDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerEventControllerUpdateProviderEvent', 'id', id)
            // verify required parameter 'updateProviderEventDto' is not null or undefined
            assertParamExists('providerEventControllerUpdateProviderEvent', 'updateProviderEventDto', updateProviderEventDto)
            const localVarPath = `/provider_events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProviderTagDto} createProviderTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerCreateProviderTag: async (createProviderTagDto: CreateProviderTagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderTagDto' is not null or undefined
            assertParamExists('providerTagControllerCreateProviderTag', 'createProviderTagDto', createProviderTagDto)
            const localVarPath = `/provider_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerDeleteProviderTag: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerTagControllerDeleteProviderTag', 'id', id)
            const localVarPath = `/provider_tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerGetProviderTag: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderTagDto} updateProviderTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerUpdateProviderTag: async (id: string, updateProviderTagDto: UpdateProviderTagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerTagControllerUpdateProviderTag', 'id', id)
            // verify required parameter 'updateProviderTagDto' is not null or undefined
            assertParamExists('providerTagControllerUpdateProviderTag', 'updateProviderTagDto', updateProviderTagDto)
            const localVarPath = `/provider_tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lineBotControllerGetGroupJoinedLog(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lineBotControllerGetGroupJoinedLog(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.lineBotControllerGetGroupJoinedLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerNames] 
         * @param {Array<string>} [times] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemRefundControllerFindAll(skip?: number, take?: number, providerNames?: Array<string>, times?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemRefundControllerFindAll(skip, take, providerNames, times, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderItemRefundControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateProviderEventDto} createProviderEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerEventControllerCreateProviderEvent(createProviderEventDto: CreateProviderEventDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerEventControllerCreateProviderEvent(createProviderEventDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerEventControllerCreateProviderEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerEventControllerDeleteProviderEvent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerEventControllerDeleteProviderEvent(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerEventControllerDeleteProviderEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [times] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerEventControllerFindProviderEvents(skip?: number, take?: number, providerIds?: Array<string>, times?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerEventControllerFindProviderEvents(skip, take, providerIds, times, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerEventControllerFindProviderEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderEventDto} updateProviderEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerEventControllerUpdateProviderEvent(id: string, updateProviderEventDto: UpdateProviderEventDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerEventControllerUpdateProviderEvent(id, updateProviderEventDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerEventControllerUpdateProviderEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateProviderTagDto} createProviderTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagControllerCreateProviderTag(createProviderTagDto: CreateProviderTagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagControllerCreateProviderTag(createProviderTagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerTagControllerCreateProviderTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagControllerDeleteProviderTag(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagControllerDeleteProviderTag(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerTagControllerDeleteProviderTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagControllerGetProviderTag(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagControllerGetProviderTag(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerTagControllerGetProviderTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderTagDto} updateProviderTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerTagControllerUpdateProviderTag(id: string, updateProviderTagDto: UpdateProviderTagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerTagControllerUpdateProviderTag(id, updateProviderTagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.providerTagControllerUpdateProviderTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lineBotControllerGetGroupJoinedLog(options?: any): AxiosPromise<void> {
            return localVarFp.lineBotControllerGetGroupJoinedLog(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerNames] 
         * @param {Array<string>} [times] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemRefundControllerFindAll(skip?: number, take?: number, providerNames?: Array<string>, times?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.orderItemRefundControllerFindAll(skip, take, providerNames, times, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProviderEventDto} createProviderEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerCreateProviderEvent(createProviderEventDto: CreateProviderEventDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerEventControllerCreateProviderEvent(createProviderEventDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerDeleteProviderEvent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerEventControllerDeleteProviderEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [times] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerFindProviderEvents(skip?: number, take?: number, providerIds?: Array<string>, times?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.providerEventControllerFindProviderEvents(skip, take, providerIds, times, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderEventDto} updateProviderEventDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerEventControllerUpdateProviderEvent(id: string, updateProviderEventDto: UpdateProviderEventDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerEventControllerUpdateProviderEvent(id, updateProviderEventDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProviderTagDto} createProviderTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerCreateProviderTag(createProviderTagDto: CreateProviderTagDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerTagControllerCreateProviderTag(createProviderTagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerDeleteProviderTag(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerTagControllerDeleteProviderTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerGetProviderTag(options?: any): AxiosPromise<void> {
            return localVarFp.providerTagControllerGetProviderTag(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderTagDto} updateProviderTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerTagControllerUpdateProviderTag(id: string, updateProviderTagDto: UpdateProviderTagDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerTagControllerUpdateProviderTag(id, updateProviderTagDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public lineBotControllerGetGroupJoinedLog(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).lineBotControllerGetGroupJoinedLog(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {Array<string>} [providerNames] 
     * @param {Array<string>} [times] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderItemRefundControllerFindAll(skip?: number, take?: number, providerNames?: Array<string>, times?: Array<string>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderItemRefundControllerFindAll(skip, take, providerNames, times, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProviderEventDto} createProviderEventDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerEventControllerCreateProviderEvent(createProviderEventDto: CreateProviderEventDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerEventControllerCreateProviderEvent(createProviderEventDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerEventControllerDeleteProviderEvent(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerEventControllerDeleteProviderEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {Array<string>} [providerIds] 
     * @param {Array<string>} [times] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerEventControllerFindProviderEvents(skip?: number, take?: number, providerIds?: Array<string>, times?: Array<string>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerEventControllerFindProviderEvents(skip, take, providerIds, times, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProviderEventDto} updateProviderEventDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerEventControllerUpdateProviderEvent(id: string, updateProviderEventDto: UpdateProviderEventDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerEventControllerUpdateProviderEvent(id, updateProviderEventDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProviderTagDto} createProviderTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerTagControllerCreateProviderTag(createProviderTagDto: CreateProviderTagDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerTagControllerCreateProviderTag(createProviderTagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerTagControllerDeleteProviderTag(id: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerTagControllerDeleteProviderTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerTagControllerGetProviderTag(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerTagControllerGetProviderTag(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProviderTagDto} updateProviderTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public providerTagControllerUpdateProviderTag(id: string, updateProviderTagDto: UpdateProviderTagDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).providerTagControllerUpdateProviderTag(id, updateProviderTagDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliveryMealsApi - axios parameter creator
 * @export
 */
export const DeliveryMealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDeliveryMealsDto} createDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerCreateDeliveryMeals: async (createDeliveryMealsDto: CreateDeliveryMealsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeliveryMealsDto' is not null or undefined
            assertParamExists('deliveryMealsControllerCreateDeliveryMeals', 'createDeliveryMealsDto', createDeliveryMealsDto)
            const localVarPath = `/delivery_meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeliveryMealsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteDeliveryMealsDto} deleteDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerDeleteDeliveryMeal: async (deleteDeliveryMealsDto: DeleteDeliveryMealsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteDeliveryMealsDto' is not null or undefined
            assertParamExists('deliveryMealsControllerDeleteDeliveryMeal', 'deleteDeliveryMealsDto', deleteDeliveryMealsDto)
            const localVarPath = `/delivery_meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDeliveryMealsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportDeliveryMealsDto} exportDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerExportDeliveryMeals: async (exportDeliveryMealsDto: ExportDeliveryMealsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportDeliveryMealsDto' is not null or undefined
            assertParamExists('deliveryMealsControllerExportDeliveryMeals', 'exportDeliveryMealsDto', exportDeliveryMealsDto)
            const localVarPath = `/delivery_meals/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportDeliveryMealsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} [locationTagId] 
         * @param {string} [providerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerFindDeliveryMeals: async (startTime: number, endTime: number, locationTagId?: string, providerId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('deliveryMealsControllerFindDeliveryMeals', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('deliveryMealsControllerFindDeliveryMeals', 'endTime', endTime)
            const localVarPath = `/delivery_meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (locationTagId !== undefined) {
                localVarQueryParameter['locationTagId'] = locationTagId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} [locationTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerFindDeliveryMealsNotes: async (startTime: number, endTime: number, locationTagId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('deliveryMealsControllerFindDeliveryMealsNotes', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('deliveryMealsControllerFindDeliveryMealsNotes', 'endTime', endTime)
            const localVarPath = `/delivery_meals/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (locationTagId !== undefined) {
                localVarQueryParameter['locationTagId'] = locationTagId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerFindDeliveryMealsProducts: async (startTime: number, endTime: number, locationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('deliveryMealsControllerFindDeliveryMealsProducts', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('deliveryMealsControllerFindDeliveryMealsProducts', 'endTime', endTime)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('deliveryMealsControllerFindDeliveryMealsProducts', 'locationId', locationId)
            const localVarPath = `/delivery_meals/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerListProviderColors: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/delivery_meals/provider_color/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDriversDto} updateDriversDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateDrivers: async (updateDriversDto: UpdateDriversDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDriversDto' is not null or undefined
            assertParamExists('deliveryMealsControllerUpdateDrivers', 'updateDriversDto', updateDriversDto)
            const localVarPath = `/delivery_meals/update_drivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDriversDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDeliveryMealRanksDto} updateDeliveryMealRanksDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateRanks: async (updateDeliveryMealRanksDto: UpdateDeliveryMealRanksDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeliveryMealRanksDto' is not null or undefined
            assertParamExists('deliveryMealsControllerUpdateRanks', 'updateDeliveryMealRanksDto', updateDeliveryMealRanksDto)
            const localVarPath = `/delivery_meals/update_ranks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryMealRanksDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDeliveryMealsRecycleBagsDto} updateDeliveryMealsRecycleBagsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateRecycleBags: async (updateDeliveryMealsRecycleBagsDto: UpdateDeliveryMealsRecycleBagsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeliveryMealsRecycleBagsDto' is not null or undefined
            assertParamExists('deliveryMealsControllerUpdateRecycleBags', 'updateDeliveryMealsRecycleBagsDto', updateDeliveryMealsRecycleBagsDto)
            const localVarPath = `/delivery_meals/update_recycle_bags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryMealsRecycleBagsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDeliveryMealStatusDto} updateDeliveryMealStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateStatus: async (id: string, updateDeliveryMealStatusDto: UpdateDeliveryMealStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deliveryMealsControllerUpdateStatus', 'id', id)
            // verify required parameter 'updateDeliveryMealStatusDto' is not null or undefined
            assertParamExists('deliveryMealsControllerUpdateStatus', 'updateDeliveryMealStatusDto', updateDeliveryMealStatusDto)
            const localVarPath = `/delivery_meals/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliveryMealStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertDeliveryMealNoteDto} upsertDeliveryMealNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpsertDeliveryMealNote: async (upsertDeliveryMealNoteDto: UpsertDeliveryMealNoteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertDeliveryMealNoteDto' is not null or undefined
            assertParamExists('deliveryMealsControllerUpsertDeliveryMealNote', 'upsertDeliveryMealNoteDto', upsertDeliveryMealNoteDto)
            const localVarPath = `/delivery_meals/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertDeliveryMealNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertProviderColorDto} upsertProviderColorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpsertProvidersColor: async (upsertProviderColorDto: UpsertProviderColorDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertProviderColorDto' is not null or undefined
            assertParamExists('deliveryMealsControllerUpsertProvidersColor', 'upsertProviderColorDto', upsertProviderColorDto)
            const localVarPath = `/delivery_meals/provider_color`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertProviderColorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryMealsApi - functional programming interface
 * @export
 */
export const DeliveryMealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryMealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDeliveryMealsDto} createDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerCreateDeliveryMeals(createDeliveryMealsDto: CreateDeliveryMealsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerCreateDeliveryMeals(createDeliveryMealsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerCreateDeliveryMeals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteDeliveryMealsDto} deleteDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerDeleteDeliveryMeal(deleteDeliveryMealsDto: DeleteDeliveryMealsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerDeleteDeliveryMeal(deleteDeliveryMealsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerDeleteDeliveryMeal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ExportDeliveryMealsDto} exportDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerExportDeliveryMeals(exportDeliveryMealsDto: ExportDeliveryMealsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerExportDeliveryMeals(exportDeliveryMealsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerExportDeliveryMeals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} [locationTagId] 
         * @param {string} [providerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerFindDeliveryMeals(startTime: number, endTime: number, locationTagId?: string, providerId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerFindDeliveryMeals(startTime, endTime, locationTagId, providerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerFindDeliveryMeals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} [locationTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerFindDeliveryMealsNotes(startTime: number, endTime: number, locationTagId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerFindDeliveryMealsNotes(startTime, endTime, locationTagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerFindDeliveryMealsNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerFindDeliveryMealsProducts(startTime: number, endTime: number, locationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerFindDeliveryMealsProducts(startTime, endTime, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerFindDeliveryMealsProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerListProviderColors(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerListProviderColors(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerListProviderColors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDriversDto} updateDriversDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerUpdateDrivers(updateDriversDto: UpdateDriversDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerUpdateDrivers(updateDriversDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerUpdateDrivers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDeliveryMealRanksDto} updateDeliveryMealRanksDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerUpdateRanks(updateDeliveryMealRanksDto: UpdateDeliveryMealRanksDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerUpdateRanks(updateDeliveryMealRanksDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerUpdateRanks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDeliveryMealsRecycleBagsDto} updateDeliveryMealsRecycleBagsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerUpdateRecycleBags(updateDeliveryMealsRecycleBagsDto: UpdateDeliveryMealsRecycleBagsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerUpdateRecycleBags(updateDeliveryMealsRecycleBagsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerUpdateRecycleBags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDeliveryMealStatusDto} updateDeliveryMealStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerUpdateStatus(id: string, updateDeliveryMealStatusDto: UpdateDeliveryMealStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerUpdateStatus(id, updateDeliveryMealStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerUpdateStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpsertDeliveryMealNoteDto} upsertDeliveryMealNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerUpsertDeliveryMealNote(upsertDeliveryMealNoteDto: UpsertDeliveryMealNoteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerUpsertDeliveryMealNote(upsertDeliveryMealNoteDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerUpsertDeliveryMealNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpsertProviderColorDto} upsertProviderColorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryMealsControllerUpsertProvidersColor(upsertProviderColorDto: UpsertProviderColorDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryMealsControllerUpsertProvidersColor(upsertProviderColorDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryMealsApi.deliveryMealsControllerUpsertProvidersColor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeliveryMealsApi - factory interface
 * @export
 */
export const DeliveryMealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryMealsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDeliveryMealsDto} createDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerCreateDeliveryMeals(createDeliveryMealsDto: CreateDeliveryMealsDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerCreateDeliveryMeals(createDeliveryMealsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteDeliveryMealsDto} deleteDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerDeleteDeliveryMeal(deleteDeliveryMealsDto: DeleteDeliveryMealsDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerDeleteDeliveryMeal(deleteDeliveryMealsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportDeliveryMealsDto} exportDeliveryMealsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerExportDeliveryMeals(exportDeliveryMealsDto: ExportDeliveryMealsDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerExportDeliveryMeals(exportDeliveryMealsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} [locationTagId] 
         * @param {string} [providerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerFindDeliveryMeals(startTime: number, endTime: number, locationTagId?: string, providerId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerFindDeliveryMeals(startTime, endTime, locationTagId, providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} [locationTagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerFindDeliveryMealsNotes(startTime: number, endTime: number, locationTagId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerFindDeliveryMealsNotes(startTime, endTime, locationTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} startTime This is milliseconds timestamp
         * @param {number} endTime This is milliseconds timestamp
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerFindDeliveryMealsProducts(startTime: number, endTime: number, locationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerFindDeliveryMealsProducts(startTime, endTime, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerListProviderColors(options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerListProviderColors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDriversDto} updateDriversDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateDrivers(updateDriversDto: UpdateDriversDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerUpdateDrivers(updateDriversDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDeliveryMealRanksDto} updateDeliveryMealRanksDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateRanks(updateDeliveryMealRanksDto: UpdateDeliveryMealRanksDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerUpdateRanks(updateDeliveryMealRanksDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDeliveryMealsRecycleBagsDto} updateDeliveryMealsRecycleBagsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateRecycleBags(updateDeliveryMealsRecycleBagsDto: UpdateDeliveryMealsRecycleBagsDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerUpdateRecycleBags(updateDeliveryMealsRecycleBagsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDeliveryMealStatusDto} updateDeliveryMealStatusDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpdateStatus(id: string, updateDeliveryMealStatusDto: UpdateDeliveryMealStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerUpdateStatus(id, updateDeliveryMealStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertDeliveryMealNoteDto} upsertDeliveryMealNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpsertDeliveryMealNote(upsertDeliveryMealNoteDto: UpsertDeliveryMealNoteDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerUpsertDeliveryMealNote(upsertDeliveryMealNoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertProviderColorDto} upsertProviderColorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryMealsControllerUpsertProvidersColor(upsertProviderColorDto: UpsertProviderColorDto, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryMealsControllerUpsertProvidersColor(upsertProviderColorDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryMealsApi - object-oriented interface
 * @export
 * @class DeliveryMealsApi
 * @extends {BaseAPI}
 */
export class DeliveryMealsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDeliveryMealsDto} createDeliveryMealsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerCreateDeliveryMeals(createDeliveryMealsDto: CreateDeliveryMealsDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerCreateDeliveryMeals(createDeliveryMealsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteDeliveryMealsDto} deleteDeliveryMealsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerDeleteDeliveryMeal(deleteDeliveryMealsDto: DeleteDeliveryMealsDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerDeleteDeliveryMeal(deleteDeliveryMealsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportDeliveryMealsDto} exportDeliveryMealsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerExportDeliveryMeals(exportDeliveryMealsDto: ExportDeliveryMealsDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerExportDeliveryMeals(exportDeliveryMealsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} startTime This is milliseconds timestamp
     * @param {number} endTime This is milliseconds timestamp
     * @param {string} [locationTagId] 
     * @param {string} [providerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerFindDeliveryMeals(startTime: number, endTime: number, locationTagId?: string, providerId?: string, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerFindDeliveryMeals(startTime, endTime, locationTagId, providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} startTime This is milliseconds timestamp
     * @param {number} endTime This is milliseconds timestamp
     * @param {string} [locationTagId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerFindDeliveryMealsNotes(startTime: number, endTime: number, locationTagId?: string, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerFindDeliveryMealsNotes(startTime, endTime, locationTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} startTime This is milliseconds timestamp
     * @param {number} endTime This is milliseconds timestamp
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerFindDeliveryMealsProducts(startTime: number, endTime: number, locationId: string, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerFindDeliveryMealsProducts(startTime, endTime, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerListProviderColors(options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerListProviderColors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDriversDto} updateDriversDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerUpdateDrivers(updateDriversDto: UpdateDriversDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerUpdateDrivers(updateDriversDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDeliveryMealRanksDto} updateDeliveryMealRanksDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerUpdateRanks(updateDeliveryMealRanksDto: UpdateDeliveryMealRanksDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerUpdateRanks(updateDeliveryMealRanksDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDeliveryMealsRecycleBagsDto} updateDeliveryMealsRecycleBagsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerUpdateRecycleBags(updateDeliveryMealsRecycleBagsDto: UpdateDeliveryMealsRecycleBagsDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerUpdateRecycleBags(updateDeliveryMealsRecycleBagsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDeliveryMealStatusDto} updateDeliveryMealStatusDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerUpdateStatus(id: string, updateDeliveryMealStatusDto: UpdateDeliveryMealStatusDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerUpdateStatus(id, updateDeliveryMealStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertDeliveryMealNoteDto} upsertDeliveryMealNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerUpsertDeliveryMealNote(upsertDeliveryMealNoteDto: UpsertDeliveryMealNoteDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerUpsertDeliveryMealNote(upsertDeliveryMealNoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertProviderColorDto} upsertProviderColorDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMealsApi
     */
    public deliveryMealsControllerUpsertProvidersColor(upsertProviderColorDto: UpsertProviderColorDto, options?: RawAxiosRequestConfig) {
        return DeliveryMealsApiFp(this.configuration).deliveryMealsControllerUpsertProvidersColor(upsertProviderColorDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DriverApi - axios parameter creator
 * @export
 */
export const DriverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDriverInfoDto} createDriverInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerCreateDriverInfo: async (createDriverInfoDto: CreateDriverInfoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDriverInfoDto' is not null or undefined
            assertParamExists('driverInfoControllerCreateDriverInfo', 'createDriverInfoDto', createDriverInfoDto)
            const localVarPath = `/driver_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDriverInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerDeleteDriverInfo: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('driverInfoControllerDeleteDriverInfo', 'id', id)
            const localVarPath = `/driver_info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerGetDriverInfo: async (skip?: number, take?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerGetDriverInfoList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver_info/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerImportDrivers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/driver_info/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDriverInfoDto} updateDriverInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerUpdateDriverInfo: async (id: string, updateDriverInfoDto: UpdateDriverInfoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('driverInfoControllerUpdateDriverInfo', 'id', id)
            // verify required parameter 'updateDriverInfoDto' is not null or undefined
            assertParamExists('driverInfoControllerUpdateDriverInfo', 'updateDriverInfoDto', updateDriverInfoDto)
            const localVarPath = `/driver_info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDriverInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverApi - functional programming interface
 * @export
 */
export const DriverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DriverApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDriverInfoDto} createDriverInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverInfoControllerCreateDriverInfo(createDriverInfoDto: CreateDriverInfoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverInfoControllerCreateDriverInfo(createDriverInfoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverApi.driverInfoControllerCreateDriverInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverInfoControllerDeleteDriverInfo(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverInfoControllerDeleteDriverInfo(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverApi.driverInfoControllerDeleteDriverInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverInfoControllerGetDriverInfo(skip?: number, take?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverInfoControllerGetDriverInfo(skip, take, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverApi.driverInfoControllerGetDriverInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverInfoControllerGetDriverInfoList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverInfoControllerGetDriverInfoList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverApi.driverInfoControllerGetDriverInfoList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverInfoControllerImportDrivers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverInfoControllerImportDrivers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverApi.driverInfoControllerImportDrivers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDriverInfoDto} updateDriverInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverInfoControllerUpdateDriverInfo(id: string, updateDriverInfoDto: UpdateDriverInfoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverInfoControllerUpdateDriverInfo(id, updateDriverInfoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DriverApi.driverInfoControllerUpdateDriverInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DriverApi - factory interface
 * @export
 */
export const DriverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DriverApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDriverInfoDto} createDriverInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerCreateDriverInfo(createDriverInfoDto: CreateDriverInfoDto, options?: any): AxiosPromise<void> {
            return localVarFp.driverInfoControllerCreateDriverInfo(createDriverInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerDeleteDriverInfo(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.driverInfoControllerDeleteDriverInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerGetDriverInfo(skip?: number, take?: number, options?: any): AxiosPromise<void> {
            return localVarFp.driverInfoControllerGetDriverInfo(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerGetDriverInfoList(options?: any): AxiosPromise<void> {
            return localVarFp.driverInfoControllerGetDriverInfoList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerImportDrivers(options?: any): AxiosPromise<void> {
            return localVarFp.driverInfoControllerImportDrivers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDriverInfoDto} updateDriverInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverInfoControllerUpdateDriverInfo(id: string, updateDriverInfoDto: UpdateDriverInfoDto, options?: any): AxiosPromise<void> {
            return localVarFp.driverInfoControllerUpdateDriverInfo(id, updateDriverInfoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverApi - object-oriented interface
 * @export
 * @class DriverApi
 * @extends {BaseAPI}
 */
export class DriverApi extends BaseAPI {
    /**
     * 
     * @param {CreateDriverInfoDto} createDriverInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverInfoControllerCreateDriverInfo(createDriverInfoDto: CreateDriverInfoDto, options?: RawAxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverInfoControllerCreateDriverInfo(createDriverInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverInfoControllerDeleteDriverInfo(id: string, options?: RawAxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverInfoControllerDeleteDriverInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverInfoControllerGetDriverInfo(skip?: number, take?: number, options?: RawAxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverInfoControllerGetDriverInfo(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverInfoControllerGetDriverInfoList(options?: RawAxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverInfoControllerGetDriverInfoList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverInfoControllerImportDrivers(options?: RawAxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverInfoControllerImportDrivers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDriverInfoDto} updateDriverInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverApi
     */
    public driverInfoControllerUpdateDriverInfo(id: string, updateDriverInfoDto: UpdateDriverInfoDto, options?: RawAxiosRequestConfig) {
        return DriverApiFp(this.configuration).driverInfoControllerUpdateDriverInfo(id, updateDriverInfoDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLocationDto} createLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerCreateLocation: async (createLocationDto: CreateLocationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLocationDto' is not null or undefined
            assertParamExists('locationControllerCreateLocation', 'createLocationDto', createLocationDto)
            const localVarPath = `/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerDeleteLocation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationControllerDeleteLocation', 'id', id)
            const localVarPath = `/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerFindLocations: async (skip?: number, take?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerListLocations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLocationDto} updateLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerUpdateLocation: async (id: string, updateLocationDto: UpdateLocationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationControllerUpdateLocation', 'id', id)
            // verify required parameter 'updateLocationDto' is not null or undefined
            assertParamExists('locationControllerUpdateLocation', 'updateLocationDto', updateLocationDto)
            const localVarPath = `/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateLocationTagDto} createLocationTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerCreateLocationTag: async (createLocationTagDto: CreateLocationTagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLocationTagDto' is not null or undefined
            assertParamExists('locationTagControllerCreateLocationTag', 'createLocationTagDto', createLocationTagDto)
            const localVarPath = `/location_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerDeleteLocationTag: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationTagControllerDeleteLocationTag', 'id', id)
            const localVarPath = `/location_tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerGetLocationTag: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLocationTagDto} updateLocationTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerUpdateLocationTag: async (id: string, updateLocationTagDto: UpdateLocationTagDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationTagControllerUpdateLocationTag', 'id', id)
            // verify required parameter 'updateLocationTagDto' is not null or undefined
            assertParamExists('locationTagControllerUpdateLocationTag', 'updateLocationTagDto', updateLocationTagDto)
            const localVarPath = `/location_tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLocationDto} createLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerCreateLocation(createLocationDto: CreateLocationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerCreateLocation(createLocationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationControllerCreateLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerDeleteLocation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerDeleteLocation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationControllerDeleteLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerFindLocations(skip?: number, take?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerFindLocations(skip, take, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationControllerFindLocations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerListLocations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerListLocations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationControllerListLocations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLocationDto} updateLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationControllerUpdateLocation(id: string, updateLocationDto: UpdateLocationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationControllerUpdateLocation(id, updateLocationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationControllerUpdateLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateLocationTagDto} createLocationTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationTagControllerCreateLocationTag(createLocationTagDto: CreateLocationTagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationTagControllerCreateLocationTag(createLocationTagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationTagControllerCreateLocationTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationTagControllerDeleteLocationTag(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationTagControllerDeleteLocationTag(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationTagControllerDeleteLocationTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationTagControllerGetLocationTag(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationTagControllerGetLocationTag(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationTagControllerGetLocationTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLocationTagDto} updateLocationTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationTagControllerUpdateLocationTag(id: string, updateLocationTagDto: UpdateLocationTagDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationTagControllerUpdateLocationTag(id, updateLocationTagDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsApi.locationTagControllerUpdateLocationTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLocationDto} createLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerCreateLocation(createLocationDto: CreateLocationDto, options?: any): AxiosPromise<void> {
            return localVarFp.locationControllerCreateLocation(createLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerDeleteLocation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.locationControllerDeleteLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerFindLocations(skip?: number, take?: number, options?: any): AxiosPromise<void> {
            return localVarFp.locationControllerFindLocations(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerListLocations(options?: any): AxiosPromise<void> {
            return localVarFp.locationControllerListLocations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLocationDto} updateLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationControllerUpdateLocation(id: string, updateLocationDto: UpdateLocationDto, options?: any): AxiosPromise<void> {
            return localVarFp.locationControllerUpdateLocation(id, updateLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateLocationTagDto} createLocationTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerCreateLocationTag(createLocationTagDto: CreateLocationTagDto, options?: any): AxiosPromise<void> {
            return localVarFp.locationTagControllerCreateLocationTag(createLocationTagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerDeleteLocationTag(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.locationTagControllerDeleteLocationTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerGetLocationTag(options?: any): AxiosPromise<void> {
            return localVarFp.locationTagControllerGetLocationTag(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLocationTagDto} updateLocationTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationTagControllerUpdateLocationTag(id: string, updateLocationTagDto: UpdateLocationTagDto, options?: any): AxiosPromise<void> {
            return localVarFp.locationTagControllerUpdateLocationTag(id, updateLocationTagDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * 
     * @param {CreateLocationDto} createLocationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationControllerCreateLocation(createLocationDto: CreateLocationDto, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationControllerCreateLocation(createLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationControllerDeleteLocation(id: string, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationControllerDeleteLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationControllerFindLocations(skip?: number, take?: number, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationControllerFindLocations(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationControllerListLocations(options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationControllerListLocations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateLocationDto} updateLocationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationControllerUpdateLocation(id: string, updateLocationDto: UpdateLocationDto, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationControllerUpdateLocation(id, updateLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateLocationTagDto} createLocationTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationTagControllerCreateLocationTag(createLocationTagDto: CreateLocationTagDto, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationTagControllerCreateLocationTag(createLocationTagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationTagControllerDeleteLocationTag(id: string, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationTagControllerDeleteLocationTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationTagControllerGetLocationTag(options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationTagControllerGetLocationTag(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateLocationTagDto} updateLocationTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public locationTagControllerUpdateLocationTag(id: string, updateLocationTagDto: UpdateLocationTagDto, options?: RawAxiosRequestConfig) {
        return LocationsApiFp(this.configuration).locationTagControllerUpdateLocationTag(id, updateLocationTagDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderItemsApi - axios parameter creator
 * @export
 */
export const OrderItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {RefundOrderItemDto} refundOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemControllerRefund: async (id: string, refundOrderItemDto: RefundOrderItemDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderItemControllerRefund', 'id', id)
            // verify required parameter 'refundOrderItemDto' is not null or undefined
            assertParamExists('orderItemControllerRefund', 'refundOrderItemDto', refundOrderItemDto)
            const localVarPath = `/order_items/refund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refundOrderItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemControllerRevokeRefund: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderItemControllerRevokeRefund', 'id', id)
            const localVarPath = `/order_items/revoke_refund/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderItemDto} updateOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemControllerUpdateOrderItem: async (id: string, updateOrderItemDto: UpdateOrderItemDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderItemControllerUpdateOrderItem', 'id', id)
            // verify required parameter 'updateOrderItemDto' is not null or undefined
            assertParamExists('orderItemControllerUpdateOrderItem', 'updateOrderItemDto', updateOrderItemDto)
            const localVarPath = `/order_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderItemsApi - functional programming interface
 * @export
 */
export const OrderItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {RefundOrderItemDto} refundOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemControllerRefund(id: string, refundOrderItemDto: RefundOrderItemDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemControllerRefund(id, refundOrderItemDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderItemsApi.orderItemControllerRefund']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemControllerRevokeRefund(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemControllerRevokeRefund(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderItemsApi.orderItemControllerRevokeRefund']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderItemDto} updateOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemControllerUpdateOrderItem(id: string, updateOrderItemDto: UpdateOrderItemDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemControllerUpdateOrderItem(id, updateOrderItemDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderItemsApi.orderItemControllerUpdateOrderItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderItemsApi - factory interface
 * @export
 */
export const OrderItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {RefundOrderItemDto} refundOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemControllerRefund(id: string, refundOrderItemDto: RefundOrderItemDto, options?: any): AxiosPromise<void> {
            return localVarFp.orderItemControllerRefund(id, refundOrderItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemControllerRevokeRefund(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderItemControllerRevokeRefund(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateOrderItemDto} updateOrderItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemControllerUpdateOrderItem(id: string, updateOrderItemDto: UpdateOrderItemDto, options?: any): AxiosPromise<void> {
            return localVarFp.orderItemControllerUpdateOrderItem(id, updateOrderItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderItemsApi - object-oriented interface
 * @export
 * @class OrderItemsApi
 * @extends {BaseAPI}
 */
export class OrderItemsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {RefundOrderItemDto} refundOrderItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemsApi
     */
    public orderItemControllerRefund(id: string, refundOrderItemDto: RefundOrderItemDto, options?: RawAxiosRequestConfig) {
        return OrderItemsApiFp(this.configuration).orderItemControllerRefund(id, refundOrderItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemsApi
     */
    public orderItemControllerRevokeRefund(id: string, options?: RawAxiosRequestConfig) {
        return OrderItemsApiFp(this.configuration).orderItemControllerRevokeRefund(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateOrderItemDto} updateOrderItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemsApi
     */
    public orderItemControllerUpdateOrderItem(id: string, updateOrderItemDto: UpdateOrderItemDto, options?: RawAxiosRequestConfig) {
        return OrderItemsApiFp(this.configuration).orderItemControllerUpdateOrderItem(id, updateOrderItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerExportMenu: async (date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('orderControllerExportMenu', 'date', date)
            const localVarPath = `/orders/export_menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} timeRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerExportOrders: async (timeRange: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeRange' is not null or undefined
            assertParamExists('orderControllerExportOrders', 'timeRange', timeRange)
            const localVarPath = `/orders/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timeRange) {
                localVarQueryParameter['timeRange'] = timeRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<string>} locationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerExportPosData: async (date: string, locationIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('orderControllerExportPosData', 'date', date)
            // verify required parameter 'locationIds' is not null or undefined
            assertParamExists('orderControllerExportPosData', 'locationIds', locationIds)
            const localVarPath = `/orders/export_pos_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (locationIds) {
                localVarQueryParameter['locationIds'] = locationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {Array<string>} [address] 
         * @param {Array<string>} [redeemTime] 
         * @param {Array<string>} [state] 
         * @param {string} [keyword] 
         * @param {Array<string>} [providerNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindOrders: async (take?: number, skip?: number, startTime?: string, endTime?: string, address?: Array<string>, redeemTime?: Array<string>, state?: Array<string>, keyword?: string, providerNames?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (address) {
                localVarQueryParameter['address'] = address;
            }

            if (redeemTime) {
                localVarQueryParameter['redeemTime'] = redeemTime;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (providerNames) {
                localVarQueryParameter['providerNames'] = providerNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerImportOrders: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendMenuDto} sendMenuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSendMenu: async (sendMenuDto: SendMenuDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMenuDto' is not null or undefined
            assertParamExists('orderControllerSendMenu', 'sendMenuDto', sendMenuDto)
            const localVarPath = `/orders/send_menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMenuDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerExportMenu(date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerExportMenu(date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.orderControllerExportMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} timeRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerExportOrders(timeRange: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerExportOrders(timeRange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.orderControllerExportOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<string>} locationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerExportPosData(date: string, locationIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerExportPosData(date, locationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.orderControllerExportPosData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {Array<string>} [address] 
         * @param {Array<string>} [redeemTime] 
         * @param {Array<string>} [state] 
         * @param {string} [keyword] 
         * @param {Array<string>} [providerNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerFindOrders(take?: number, skip?: number, startTime?: string, endTime?: string, address?: Array<string>, redeemTime?: Array<string>, state?: Array<string>, keyword?: string, providerNames?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerFindOrders(take, skip, startTime, endTime, address, redeemTime, state, keyword, providerNames, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.orderControllerFindOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerImportOrders(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerImportOrders(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.orderControllerImportOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SendMenuDto} sendMenuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerSendMenu(sendMenuDto: SendMenuDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerSendMenu(sendMenuDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.orderControllerSendMenu']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerExportMenu(date: string, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerExportMenu(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} timeRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerExportOrders(timeRange: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerExportOrders(timeRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {Array<string>} locationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerExportPosData(date: string, locationIds: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerExportPosData(date, locationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {Array<string>} [address] 
         * @param {Array<string>} [redeemTime] 
         * @param {Array<string>} [state] 
         * @param {string} [keyword] 
         * @param {Array<string>} [providerNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerFindOrders(take?: number, skip?: number, startTime?: string, endTime?: string, address?: Array<string>, redeemTime?: Array<string>, state?: Array<string>, keyword?: string, providerNames?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerFindOrders(take, skip, startTime, endTime, address, redeemTime, state, keyword, providerNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerImportOrders(options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerImportOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendMenuDto} sendMenuDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerSendMenu(sendMenuDto: SendMenuDto, options?: any): AxiosPromise<void> {
            return localVarFp.orderControllerSendMenu(sendMenuDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public orderControllerExportMenu(date: string, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).orderControllerExportMenu(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} timeRange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public orderControllerExportOrders(timeRange: Array<string>, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).orderControllerExportOrders(timeRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {Array<string>} locationIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public orderControllerExportPosData(date: string, locationIds: Array<string>, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).orderControllerExportPosData(date, locationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {string} [startTime] 
     * @param {string} [endTime] 
     * @param {Array<string>} [address] 
     * @param {Array<string>} [redeemTime] 
     * @param {Array<string>} [state] 
     * @param {string} [keyword] 
     * @param {Array<string>} [providerNames] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public orderControllerFindOrders(take?: number, skip?: number, startTime?: string, endTime?: string, address?: Array<string>, redeemTime?: Array<string>, state?: Array<string>, keyword?: string, providerNames?: Array<string>, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).orderControllerFindOrders(take, skip, startTime, endTime, address, redeemTime, state, keyword, providerNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public orderControllerImportOrders(options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).orderControllerImportOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendMenuDto} sendMenuDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public orderControllerSendMenu(sendMenuDto: SendMenuDto, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).orderControllerSendMenu(sendMenuDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGetPermissions: async (skip?: number, take?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerListPermissions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionControllerGetPermissions(skip?: number, take?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionControllerGetPermissions(skip, take, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionControllerGetPermissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionControllerListPermissions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionControllerListPermissions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.permissionControllerListPermissions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGetPermissions(skip?: number, take?: number, options?: any): AxiosPromise<void> {
            return localVarFp.permissionControllerGetPermissions(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerListPermissions(options?: any): AxiosPromise<void> {
            return localVarFp.permissionControllerListPermissions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionControllerGetPermissions(skip?: number, take?: number, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionControllerGetPermissions(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionControllerListPermissions(options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionControllerListPermissions(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerDeleteProduct: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productControllerDeleteProduct', 'id', id)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerExportProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [name] 
         * @param {boolean} [isToppings] 
         * @param {Array<ProductControllerGetProductsStateEnum>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetProducts: async (take?: number, skip?: number, providerIds?: Array<string>, name?: string, isToppings?: boolean, state?: Array<ProductControllerGetProductsStateEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isToppings !== undefined) {
                localVarQueryParameter['isToppings'] = isToppings;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdateProduct: async (id: string, updateProductDto: UpdateProductDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productControllerUpdateProduct', 'id', id)
            // verify required parameter 'updateProductDto' is not null or undefined
            assertParamExists('productControllerUpdateProduct', 'updateProductDto', updateProductDto)
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerDeleteProduct(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerDeleteProduct(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productControllerDeleteProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerExportProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerExportProducts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productControllerExportProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [name] 
         * @param {boolean} [isToppings] 
         * @param {Array<ProductControllerGetProductsStateEnum>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerGetProducts(take?: number, skip?: number, providerIds?: Array<string>, name?: string, isToppings?: boolean, state?: Array<ProductControllerGetProductsStateEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindProductsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerGetProducts(take, skip, providerIds, name, isToppings, state, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productControllerGetProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerImportProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerImportProducts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productControllerImportProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productControllerUpdateProduct(id: string, updateProductDto: UpdateProductDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productControllerUpdateProduct(id, updateProductDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productControllerUpdateProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerDeleteProduct(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerDeleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerExportProducts(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerExportProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {Array<string>} [providerIds] 
         * @param {string} [name] 
         * @param {boolean} [isToppings] 
         * @param {Array<ProductControllerGetProductsStateEnum>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerGetProducts(take?: number, skip?: number, providerIds?: Array<string>, name?: string, isToppings?: boolean, state?: Array<ProductControllerGetProductsStateEnum>, options?: any): AxiosPromise<FindProductsResult> {
            return localVarFp.productControllerGetProducts(take, skip, providerIds, name, isToppings, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerImportProducts(options?: any): AxiosPromise<void> {
            return localVarFp.productControllerImportProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductDto} updateProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productControllerUpdateProduct(id: string, updateProductDto: UpdateProductDto, options?: any): AxiosPromise<void> {
            return localVarFp.productControllerUpdateProduct(id, updateProductDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productControllerDeleteProduct(id: string, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productControllerDeleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productControllerExportProducts(options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productControllerExportProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {Array<string>} [providerIds] 
     * @param {string} [name] 
     * @param {boolean} [isToppings] 
     * @param {Array<ProductControllerGetProductsStateEnum>} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productControllerGetProducts(take?: number, skip?: number, providerIds?: Array<string>, name?: string, isToppings?: boolean, state?: Array<ProductControllerGetProductsStateEnum>, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productControllerGetProducts(take, skip, providerIds, name, isToppings, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productControllerImportProducts(options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productControllerImportProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProductDto} updateProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productControllerUpdateProduct(id: string, updateProductDto: UpdateProductDto, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productControllerUpdateProduct(id, updateProductDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ProductControllerGetProductsStateEnum = {
    Supplying: 'SUPPLYING',
    Discontinued: 'DISCONTINUED'
} as const;
export type ProductControllerGetProductsStateEnum = typeof ProductControllerGetProductsStateEnum[keyof typeof ProductControllerGetProductsStateEnum];


/**
 * ProviderStatementsApi - axios parameter creator
 * @export
 */
export const ProviderStatementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CancelStatementDto} cancelStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerCancelStatement: async (id: string, cancelStatementDto: CancelStatementDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('statementControllerCancelStatement', 'id', id)
            // verify required parameter 'cancelStatementDto' is not null or undefined
            assertParamExists('statementControllerCancelStatement', 'cancelStatementDto', cancelStatementDto)
            const localVarPath = `/provider_statements/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelStatementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportStatementsDto} exportStatementsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerExportStatements: async (exportStatementsDto: ExportStatementsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportStatementsDto' is not null or undefined
            assertParamExists('statementControllerExportStatements', 'exportStatementsDto', exportStatementsDto)
            const localVarPath = `/provider_statements/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportStatementsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerFindPendingDetail: async (ids: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('statementControllerFindPendingDetail', 'ids', ids)
            const localVarPath = `/provider_statements/pending_detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [states] 
         * @param {Array<string>} [timeRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerFindStatements: async (skip?: number, take?: number, providerIds?: Array<string>, states?: Array<string>, timeRange?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider_statements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (providerIds) {
                localVarQueryParameter['providerIds'] = providerIds;
            }

            if (states) {
                localVarQueryParameter['states'] = states;
            }

            if (timeRange) {
                localVarQueryParameter['timeRange'] = timeRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerGetIgnoredToppings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provider_statements/ignored_toppings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PayStatementsDto} payStatementsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerPayStatements: async (payStatementsDto: PayStatementsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payStatementsDto' is not null or undefined
            assertParamExists('statementControllerPayStatements', 'payStatementsDto', payStatementsDto)
            const localVarPath = `/provider_statements/pay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payStatementsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ResolveStatementDto} resolveStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerResolveStatement: async (id: string, resolveStatementDto: ResolveStatementDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('statementControllerResolveStatement', 'id', id)
            // verify required parameter 'resolveStatementDto' is not null or undefined
            assertParamExists('statementControllerResolveStatement', 'resolveStatementDto', resolveStatementDto)
            const localVarPath = `/provider_statements/{id}/resolve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveStatementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendConfirmNotificationDto} sendConfirmNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerSendConfirmNotification: async (sendConfirmNotificationDto: SendConfirmNotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendConfirmNotificationDto' is not null or undefined
            assertParamExists('statementControllerSendConfirmNotification', 'sendConfirmNotificationDto', sendConfirmNotificationDto)
            const localVarPath = `/provider_statements/send_confirm_notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendConfirmNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateIgnoredToppingsDto} updateIgnoredToppingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerUpdateIgnoredToppings: async (updateIgnoredToppingsDto: UpdateIgnoredToppingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateIgnoredToppingsDto' is not null or undefined
            assertParamExists('statementControllerUpdateIgnoredToppings', 'updateIgnoredToppingsDto', updateIgnoredToppingsDto)
            const localVarPath = `/provider_statements/ignored_toppings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIgnoredToppingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStatementDto} updateStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerUpdateStatement: async (id: string, updateStatementDto: UpdateStatementDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('statementControllerUpdateStatement', 'id', id)
            // verify required parameter 'updateStatementDto' is not null or undefined
            assertParamExists('statementControllerUpdateStatement', 'updateStatementDto', updateStatementDto)
            const localVarPath = `/provider_statements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStatementDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderStatementsApi - functional programming interface
 * @export
 */
export const ProviderStatementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderStatementsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CancelStatementDto} cancelStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerCancelStatement(id: string, cancelStatementDto: CancelStatementDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerCancelStatement(id, cancelStatementDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerCancelStatement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ExportStatementsDto} exportStatementsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerExportStatements(exportStatementsDto: ExportStatementsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerExportStatements(exportStatementsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerExportStatements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerFindPendingDetail(ids: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerFindPendingDetail(ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerFindPendingDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [states] 
         * @param {Array<string>} [timeRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerFindStatements(skip?: number, take?: number, providerIds?: Array<string>, states?: Array<string>, timeRange?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerFindStatements(skip, take, providerIds, states, timeRange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerFindStatements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerGetIgnoredToppings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerGetIgnoredToppings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerGetIgnoredToppings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PayStatementsDto} payStatementsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerPayStatements(payStatementsDto: PayStatementsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerPayStatements(payStatementsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerPayStatements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ResolveStatementDto} resolveStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerResolveStatement(id: string, resolveStatementDto: ResolveStatementDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerResolveStatement(id, resolveStatementDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerResolveStatement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SendConfirmNotificationDto} sendConfirmNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerSendConfirmNotification(sendConfirmNotificationDto: SendConfirmNotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerSendConfirmNotification(sendConfirmNotificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerSendConfirmNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateIgnoredToppingsDto} updateIgnoredToppingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerUpdateIgnoredToppings(updateIgnoredToppingsDto: UpdateIgnoredToppingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerUpdateIgnoredToppings(updateIgnoredToppingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerUpdateIgnoredToppings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStatementDto} updateStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementControllerUpdateStatement(id: string, updateStatementDto: UpdateStatementDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statementControllerUpdateStatement(id, updateStatementDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProviderStatementsApi.statementControllerUpdateStatement']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProviderStatementsApi - factory interface
 * @export
 */
export const ProviderStatementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderStatementsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CancelStatementDto} cancelStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerCancelStatement(id: string, cancelStatementDto: CancelStatementDto, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerCancelStatement(id, cancelStatementDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportStatementsDto} exportStatementsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerExportStatements(exportStatementsDto: ExportStatementsDto, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerExportStatements(exportStatementsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerFindPendingDetail(ids: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerFindPendingDetail(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<string>} [providerIds] 
         * @param {Array<string>} [states] 
         * @param {Array<string>} [timeRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerFindStatements(skip?: number, take?: number, providerIds?: Array<string>, states?: Array<string>, timeRange?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerFindStatements(skip, take, providerIds, states, timeRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerGetIgnoredToppings(options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerGetIgnoredToppings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PayStatementsDto} payStatementsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerPayStatements(payStatementsDto: PayStatementsDto, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerPayStatements(payStatementsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ResolveStatementDto} resolveStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerResolveStatement(id: string, resolveStatementDto: ResolveStatementDto, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerResolveStatement(id, resolveStatementDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendConfirmNotificationDto} sendConfirmNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerSendConfirmNotification(sendConfirmNotificationDto: SendConfirmNotificationDto, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerSendConfirmNotification(sendConfirmNotificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateIgnoredToppingsDto} updateIgnoredToppingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerUpdateIgnoredToppings(updateIgnoredToppingsDto: UpdateIgnoredToppingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerUpdateIgnoredToppings(updateIgnoredToppingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateStatementDto} updateStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementControllerUpdateStatement(id: string, updateStatementDto: UpdateStatementDto, options?: any): AxiosPromise<void> {
            return localVarFp.statementControllerUpdateStatement(id, updateStatementDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderStatementsApi - object-oriented interface
 * @export
 * @class ProviderStatementsApi
 * @extends {BaseAPI}
 */
export class ProviderStatementsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {CancelStatementDto} cancelStatementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerCancelStatement(id: string, cancelStatementDto: CancelStatementDto, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerCancelStatement(id, cancelStatementDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportStatementsDto} exportStatementsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerExportStatements(exportStatementsDto: ExportStatementsDto, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerExportStatements(exportStatementsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerFindPendingDetail(ids: Array<string>, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerFindPendingDetail(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {Array<string>} [providerIds] 
     * @param {Array<string>} [states] 
     * @param {Array<string>} [timeRange] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerFindStatements(skip?: number, take?: number, providerIds?: Array<string>, states?: Array<string>, timeRange?: Array<string>, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerFindStatements(skip, take, providerIds, states, timeRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerGetIgnoredToppings(options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerGetIgnoredToppings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PayStatementsDto} payStatementsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerPayStatements(payStatementsDto: PayStatementsDto, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerPayStatements(payStatementsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ResolveStatementDto} resolveStatementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerResolveStatement(id: string, resolveStatementDto: ResolveStatementDto, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerResolveStatement(id, resolveStatementDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendConfirmNotificationDto} sendConfirmNotificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerSendConfirmNotification(sendConfirmNotificationDto: SendConfirmNotificationDto, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerSendConfirmNotification(sendConfirmNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateIgnoredToppingsDto} updateIgnoredToppingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerUpdateIgnoredToppings(updateIgnoredToppingsDto: UpdateIgnoredToppingsDto, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerUpdateIgnoredToppings(updateIgnoredToppingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateStatementDto} updateStatementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderStatementsApi
     */
    public statementControllerUpdateStatement(id: string, updateStatementDto: UpdateStatementDto, options?: RawAxiosRequestConfig) {
        return ProviderStatementsApiFp(this.configuration).statementControllerUpdateStatement(id, updateStatementDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProvidersApi - axios parameter creator
 * @export
 */
export const ProvidersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProviderDto} createProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreateProvider: async (createProviderDto: CreateProviderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProviderDto' is not null or undefined
            assertParamExists('providerControllerCreateProvider', 'createProviderDto', createProviderDto)
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerDeleteProvider: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerDeleteProvider', 'id', id)
            const localVarPath = `/providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {any} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerReadProviders: async (keyword?: string, types?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (types !== undefined) {
                for (const [key, value] of Object.entries(types)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerReadProvidersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/providers/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateProvider: async (id: string, updateProviderDto: UpdateProviderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('providerControllerUpdateProvider', 'id', id)
            // verify required parameter 'updateProviderDto' is not null or undefined
            assertParamExists('providerControllerUpdateProvider', 'updateProviderDto', updateProviderDto)
            const localVarPath = `/providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProviderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvidersApi - functional programming interface
 * @export
 */
export const ProvidersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProvidersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProviderDto} createProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerCreateProvider(createProviderDto: CreateProviderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerCreateProvider(createProviderDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.providerControllerCreateProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerDeleteProvider(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerDeleteProvider(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.providerControllerDeleteProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {any} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerReadProviders(keyword?: string, types?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerReadProviders(keyword, types, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.providerControllerReadProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerReadProvidersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerReadProvidersList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.providerControllerReadProvidersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async providerControllerUpdateProvider(id: string, updateProviderDto: UpdateProviderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.providerControllerUpdateProvider(id, updateProviderDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProvidersApi.providerControllerUpdateProvider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProvidersApi - factory interface
 * @export
 */
export const ProvidersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProvidersApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProviderDto} createProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerCreateProvider(createProviderDto: CreateProviderDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerCreateProvider(createProviderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerDeleteProvider(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerDeleteProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [keyword] 
         * @param {any} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerReadProviders(keyword?: string, types?: any, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerReadProviders(keyword, types, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerReadProvidersList(options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerReadProvidersList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProviderDto} updateProviderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        providerControllerUpdateProvider(id: string, updateProviderDto: UpdateProviderDto, options?: any): AxiosPromise<void> {
            return localVarFp.providerControllerUpdateProvider(id, updateProviderDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
export class ProvidersApi extends BaseAPI {
    /**
     * 
     * @param {CreateProviderDto} createProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providerControllerCreateProvider(createProviderDto: CreateProviderDto, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).providerControllerCreateProvider(createProviderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providerControllerDeleteProvider(id: string, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).providerControllerDeleteProvider(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [keyword] 
     * @param {any} [types] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providerControllerReadProviders(keyword?: string, types?: any, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).providerControllerReadProviders(keyword, types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providerControllerReadProvidersList(options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).providerControllerReadProvidersList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProviderDto} updateProviderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public providerControllerUpdateProvider(id: string, updateProviderDto: UpdateProviderDto, options?: RawAxiosRequestConfig) {
        return ProvidersApiFp(this.configuration).providerControllerUpdateProvider(id, updateProviderDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreateRole: async (createRoleDto: CreateRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleDto' is not null or undefined
            assertParamExists('roleControllerCreateRole', 'createRoleDto', createRoleDto)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDeleteRole: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerDeleteRole', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetRoles: async (skip?: number, take?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerListRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdateRole: async (id: string, createRoleDto: CreateRoleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerUpdateRole', 'id', id)
            // verify required parameter 'createRoleDto' is not null or undefined
            assertParamExists('roleControllerUpdateRole', 'createRoleDto', createRoleDto)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerCreateRole(createRoleDto: CreateRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerCreateRole(createRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.roleControllerCreateRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerDeleteRole(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerDeleteRole(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.roleControllerDeleteRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGetRoles(skip?: number, take?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGetRoles(skip, take, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.roleControllerGetRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerListRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerListRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.roleControllerListRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerUpdateRole(id: string, createRoleDto: CreateRoleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerUpdateRole(id, createRoleDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.roleControllerUpdateRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreateRole(createRoleDto: CreateRoleDto, options?: any): AxiosPromise<void> {
            return localVarFp.roleControllerCreateRole(createRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerDeleteRole(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.roleControllerDeleteRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetRoles(skip?: number, take?: number, options?: any): AxiosPromise<void> {
            return localVarFp.roleControllerGetRoles(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerListRoles(options?: any): AxiosPromise<void> {
            return localVarFp.roleControllerListRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdateRole(id: string, createRoleDto: CreateRoleDto, options?: any): AxiosPromise<void> {
            return localVarFp.roleControllerUpdateRole(id, createRoleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoleDto} createRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerCreateRole(createRoleDto: CreateRoleDto, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerCreateRole(createRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerDeleteRole(id: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerDeleteRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGetRoles(skip?: number, take?: number, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGetRoles(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerListRoles(options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerListRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateRoleDto} createRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerUpdateRole(id: string, createRoleDto: CreateRoleDto, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerUpdateRole(id, createRoleDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerApproveUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerApproveUser', 'id', id)
            const localVarPath = `/users/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<UserControllerFindUsersStateEnum>} [state] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindUsers: async (skip?: number, take?: number, state?: Array<UserControllerFindUsersStateEnum>, keyword?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerListUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSuspendUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerSuspendUser', 'id', id)
            const localVarPath = `/users/{id}/suspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnsuspendUser: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerUnsuspendUser', 'id', id)
            const localVarPath = `/users/{id}/unsuspend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMyProfileDto} updateMyProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateProfile: async (updateMyProfileDto: UpdateMyProfileDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMyProfileDto' is not null or undefined
            assertParamExists('userControllerUpdateProfile', 'updateMyProfileDto', updateMyProfileDto)
            const localVarPath = `/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser: async (id: string, updateUserDto: UpdateUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'id', id)
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerApproveUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerApproveUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerApproveUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<UserControllerFindUsersStateEnum>} [state] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindUsers(skip?: number, take?: number, state?: Array<UserControllerFindUsersStateEnum>, keyword?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindUsersResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindUsers(skip, take, state, keyword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerFindUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyProfileResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerGetMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerListUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerListUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerListUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerSuspendUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerSuspendUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerSuspendUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUnsuspendUser(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUnsuspendUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerUnsuspendUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateMyProfileDto} updateMyProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateProfile(updateMyProfileDto: UpdateMyProfileDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateProfile(updateMyProfileDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerUpdateProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUser(id: string, updateUserDto: UpdateUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUser(id, updateUserDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.userControllerUpdateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerApproveUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerApproveUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {Array<UserControllerFindUsersStateEnum>} [state] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindUsers(skip?: number, take?: number, state?: Array<UserControllerFindUsersStateEnum>, keyword?: string, options?: any): AxiosPromise<FindUsersResult> {
            return localVarFp.userControllerFindUsers(skip, take, state, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetMe(options?: any): AxiosPromise<GetMyProfileResult> {
            return localVarFp.userControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerListUsers(options?: any): AxiosPromise<void> {
            return localVarFp.userControllerListUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSuspendUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerSuspendUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnsuspendUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUnsuspendUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMyProfileDto} updateMyProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateProfile(updateMyProfileDto: UpdateMyProfileDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUpdateProfile(updateMyProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser(id: string, updateUserDto: UpdateUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerUpdateUser(id, updateUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerApproveUser(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerApproveUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {Array<UserControllerFindUsersStateEnum>} [state] 
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerFindUsers(skip?: number, take?: number, state?: Array<UserControllerFindUsersStateEnum>, keyword?: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerFindUsers(skip, take, state, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetMe(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerListUsers(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerListUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerSuspendUser(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerSuspendUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUnsuspendUser(id: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUnsuspendUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMyProfileDto} updateMyProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateProfile(updateMyProfileDto: UpdateMyProfileDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUpdateProfile(updateMyProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateUser(id: string, updateUserDto: UpdateUserDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUpdateUser(id, updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UserControllerFindUsersStateEnum = {
    Activated: 'ACTIVATED',
    Reviewing: 'REVIEWING',
    Suspended: 'SUSPENDED'
} as const;
export type UserControllerFindUsersStateEnum = typeof UserControllerFindUsersStateEnum[keyof typeof UserControllerFindUsersStateEnum];


