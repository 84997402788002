import { Form, FormInstance, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect } from "react";
import { UserSelectorContainer } from "../../shared/user-selector/user-selector.container";
import { PermissionSelectorContainer } from "../../shared/permission-selector/permission-selector.container";

type RoleFormComponentProps = {
  form: FormInstance;
  role?: {
    id: string;
    description: string;
    name: string;
    isProtected: boolean;
    permissionIds: Array<string>;
    userIds: Array<string>;
  };
};
export function RoleFormComponent(props: RoleFormComponentProps) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} initialValues={props.role}>
      <FormItem
        label='名稱'
        name='name'
        rules={[{ required: true, message: "請輸入名稱" }]}
      >
        <Input />
      </FormItem>
      <FormItem label='描述' name='desc'>
        <Input />
      </FormItem>
      <UserSelectorContainer multiple />
      <PermissionSelectorContainer multiple />
    </Form>
  );
}
