export enum PROVIDER_STATUS_TYPE {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
}

export const PROVIDER_STATUS_MAPPING = {
  [PROVIDER_STATUS_TYPE.PENDING]: 'default',
  [PROVIDER_STATUS_TYPE.CONFIRMED]: 'success',
  [PROVIDER_STATUS_TYPE.REJECTED]: 'error',
};
