/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Form, FormInstance, Radio } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_TW';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { ProviderSelectorContainer } from '../shared/provider-selector/provider-selector.containers';
import { FunctionInfoComponent } from '../shared/function-info.component';

type Props = {
  form: FormInstance;
};

export function SendMenuFormComponent(props: Props) {
  const type = useWatch('type', props.form);

  useEffect(() => {
    props.form.setFieldsValue({
      content: undefined,
    });
  }, [type]);

  return (
    <Form
      form={props.form}
      className="w-full"
      initialValues={{
        type: 'MEAL',
        date: dayjs().startOf('day'),
      }}
    >
      <Form.Item>請選擇要匯出的取貨日期。</Form.Item>
      <Form.Item
        name="date"
        label="日期"
        rules={[
          {
            required: true,
            message: '請選擇日期',
          },
        ]}
      >
        <DatePicker locale={locale} />
      </Form.Item>
      <Form.Item
        name="type"
        label="發餐類型"
        rules={[
          {
            required: true,
            message: '請選擇日期',
          },
        ]}
      >
        <Radio.Group
          options={[
            { label: '餐點', value: 'MEAL' },
            { label: '飲料', value: 'DRINK' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="content"
        label="訊息內容"
        rules={[
          {
            required: true,
            message: '請選擇訊息內容',
          },
        ]}
      >
        <Radio.Group
          options={[
            ...(type === 'MEAL'
              ? [
                  { label: '品項總數', value: 'ITEM_TOTAL' },
                  {
                    label: '結單總數 + 分裝',
                    value: 'SUMMARY_ADN_LOCATION_SUMMARY',
                  },
                ]
              : []),
            ...(type === 'DRINK'
              ? [
                  { label: '當前A，前一天15:30～當天08:30', value: 'DRINK_A' },
                  {
                    label: '追加B，當天08:31～當天10:00',
                    value: 'DRINK_B',
                  },
                ]
              : []),
          ]}
        />
      </Form.Item>
      <ProviderSelectorContainer
        multiple
        label={
          <div className="flex items-center gap-2">
            <span>供應商</span>
            <FunctionInfoComponent
              title={<span>若不選擇供應商，將會發送所有供應商的菜單。</span>}
            />
          </div>
        }
      />
    </Form>
  );
}
