import { Modal as AntModal } from 'antd';
import dayjs from 'dayjs';
import { map } from 'lodash';
import { useEffect } from 'react';

import schedules from '../schedules';

type ScheduleDriversModalProps = {
  modalInfo: any;
  handleModalConfirm: Function;
  handleModalClose: Function;
  listData: any;
  renderListData: Function;
  form: any;
};

export function ScheduleDriversModal({
  modalInfo,
  handleModalClose,
  handleModalConfirm,
  listData,
  renderListData,
  form,
}: ScheduleDriversModalProps) {
  useEffect(() => {
    if (modalInfo.open) {
      form.setFieldValue(
        'deliveryMeals',
        map(
          listData[modalInfo.locationTagId]?.data[modalInfo.dateTimestamp],
          (item) => ({
            id: item.id,
            driverId: item.driver?.id || null,
          })
        ) || []
      );
    }
  }, [form, modalInfo, listData]);

  return (
    <AntModal
      className="page-schedule-meals"
      open={modalInfo.open}
      title={
        <div className="text-xl font-bold mb-6">
          {dayjs(modalInfo.dateTimestamp).format('YYYY-MM-DD')} -{' '}
          {modalInfo.locationTagId && listData[modalInfo.locationTagId].name}
        </div>
      }
      cancelText="取消"
      onCancel={() => handleModalClose()}
      okText="確認"
      onOk={async () => {
        const data = await form.validateFields();
        if (modalInfo.locationTagId && modalInfo.dateTimestamp) {
          handleModalConfirm(data);
        }
      }}
      closeIcon={null}
    >
      {modalInfo.open && modalInfo.locationTagId && modalInfo.dateTimestamp
        ? renderListData(
            listData[modalInfo.locationTagId].data ||
              Array<schedules.CellData>(),
            modalInfo.dateTimestamp,
            'modal'
          )
        : '暫無資料'}
    </AntModal>
  );
}
