import { InfoCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ReactNode } from "react";

type Props = {
  title: ReactNode;
};

export function FunctionInfoComponent(props: Props) {
  return (
    <Tooltip title={props.title}>
      <InfoCircleTwoTone />
    </Tooltip>
  );
}
