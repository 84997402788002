import {
  useCreateProviderTag,
  useDeleteProviderTag,
  useProviderTags,
  useUpdateProviderTag,
} from "../../utils/api.hook";
import { ProvidersTagsComponent } from "./providers-tags.component";

export function ProvidersTagsContainer() {
  const { data: { providerTags } = {}, isLoading: isFetchingProviderTags } =
    useProviderTags();
  const { mutateAsync: createProviderTag, isLoading: isCreatingProviderTag } =
    useCreateProviderTag();
  const { mutateAsync: updateProviderTag, isLoading: isUpdatingProviderTag } =
    useUpdateProviderTag();
  const { mutateAsync: deleteProviderTag, isLoading: isDeletingProviderTag } =
    useDeleteProviderTag();

  return (
    <ProvidersTagsComponent
      createProviderTag={createProviderTag}
      deleteProviderTag={deleteProviderTag}
      providerTags={providerTags}
      updateProviderTag={updateProviderTag}
      isLoading={
        isCreatingProviderTag ||
        isDeletingProviderTag ||
        isFetchingProviderTags ||
        isUpdatingProviderTag
      }
    />
  );
}
