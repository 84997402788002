export enum EXPORT_PERIOD {
  FIRST_HALF = "FIRST_HALF",
  LATTER_HALF = "LATTER_HALF",
}

const EXPORT_PERIOD_LABEL = {
  [EXPORT_PERIOD.FIRST_HALF]: "前半月",
  [EXPORT_PERIOD.LATTER_HALF]: "後半月",
};

export const EXPORT_PERIOD_OPTIONS = [
  {
    value: EXPORT_PERIOD.FIRST_HALF,
    label: EXPORT_PERIOD_LABEL[EXPORT_PERIOD.FIRST_HALF],
  },
  {
    value: EXPORT_PERIOD.LATTER_HALF,
    label: EXPORT_PERIOD_LABEL[EXPORT_PERIOD.LATTER_HALF],
  },
];
