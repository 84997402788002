import { useMemo } from 'react';
import {
  Row,
  Col,
  Typography,
  Button,
  List,
  Card,
  Space,
  Flex,
  Input,
  Alert,
} from 'antd';
import {
  AppstoreAddOutlined,
  CopyOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { isEmpty, keys, map } from 'lodash';
import schedules from '../schedules';
import FormItem from 'antd/es/form/FormItem';

const WEEK_TRANSLATE = ['日', '一', '二', '三', '四', '五', '六'];

type CalendarHeaderProps = {
  value: Dayjs;
  onChange(date: Dayjs): void;
};

export function CalendarHeader({ value, onChange }: CalendarHeaderProps) {
  return (
    <Space align="baseline">
      <Row gutter={8}>
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.subtract(14, 'days'));
            }}
          >
            上兩週
          </Button>
        </Col>
        <Col>
          <Button
            size="small"
            onClick={() => {
              onChange(value.add(14, 'days'));
            }}
          >
            下兩週
          </Button>
        </Col>
      </Row>
    </Space>
  );
}

type CalendarCellProps = {
  listData: schedules.MappingGroupByTimestamp;
  locationTagId: string;
  locationTagName: string;
  disabled: boolean;
  isEditMode: boolean;
  onClickAdd: Function;
  onCopyParams: Function;
  onSaveNote: Function;
  noteData: { [time: number]: { [locationTagId: string]: string } };
  renderListData: Function;
};

export function CalendarCell({
  listData,
  locationTagName,
  locationTagId,
  disabled,
  isEditMode,
  onClickAdd,
  onCopyParams,
  onSaveNote,
  noteData,
  renderListData,
}: CalendarCellProps) {
  const allTimestamp = useMemo(() => keys(listData), [listData]);

  return (
    <Card>
      <Typography.Title level={5} className="location-tag-title">
        {locationTagName}
      </Typography.Title>

      <div>
        {map(allTimestamp, (timestamp: number) => {
          timestamp = Number(timestamp);
          const note = noteData?.[timestamp]?.[locationTagId];

          return (
            <List.Item key={timestamp}>
              <Typography.Text strong>
                {dayjs(timestamp).format('YYYY-MM-DD')} (
                {WEEK_TRANSLATE[dayjs(timestamp).get('day')]})
              </Typography.Text>

              <Flex gap="small">
                {!isEmpty(listData[timestamp]) && (
                  <Button
                    icon={<CopyOutlined />}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onCopyParams(locationTagId, timestamp);
                    }}
                  >
                    複製參數
                  </Button>
                )}

                {!disabled && (
                  <Button
                    icon={<AppstoreAddOutlined />}
                    size="small"
                    className="button-insert"
                    onClick={async (e) => {
                      e.stopPropagation();
                      onClickAdd(locationTagId, timestamp);
                    }}
                  >
                    新增
                  </Button>
                )}
              </Flex>

              {isEditMode && (
                <Space.Compact
                  size="small"
                  block
                  style={{ marginTop: 3, marginBottom: 3 }}
                >
                  <FormItem name={['notes', timestamp, locationTagId]} noStyle>
                    <Input placeholder="請輸入備註" />
                  </FormItem>

                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    onClick={() => onSaveNote(locationTagId, timestamp)}
                  />
                </Space.Compact>
              )}

              {!isEditMode && note && (
                <Alert
                  style={{ width: '100%' }}
                  message={note}
                  type="info"
                  showIcon
                />
              )}

              {renderListData(
                listData || Array<schedules.CellData>(),
                timestamp
              )}
            </List.Item>
          );
        })}
      </div>
    </Card>
  );
}
