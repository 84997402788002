import { sortBy } from "lodash";
import { useListProviders } from "../../utils/api.hook";
import { ProviderSelectorComponent } from "./provider-selector.component";
import { ReactNode } from "react";

type Props = {
  multiple?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
};

export function ProviderSelectorContainer(data: Props) {
  const { isLoading, data: { providers } = {} } = useListProviders();

  return (
    <ProviderSelectorComponent
      isDisabled={data.isDisabled}
      isLoading={isLoading}
      isRequired={data.isRequired}
      label={data.label}
      multiple={data.multiple}
      providers={sortBy(providers, (provider) =>
        parseInt(provider.idNumber, 10)
      )}
    />
  );
}
