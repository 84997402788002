import { map } from "lodash";
import { useListPermissions } from "../../utils/api.hook";
import { PermissionSelectorComponent } from "./permission-selector.component";

type Props = {
  multiple?: boolean;
  required?: boolean;
};

export function PermissionSelectorContainer(props: Props) {
  const {
    data: { permissions } = {},
    isLoading,
    isFetching,
  } = useListPermissions();

  return (
    <PermissionSelectorComponent
      isLoading={isLoading || isFetching}
      multiple={props.multiple}
      options={
        map(permissions, (permission) => ({
          label: `${permission.name} (${permission.id})`,
          value: permission.id,
        })) || []
      }
    />
  );
}
