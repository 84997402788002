import { Table } from "antd";
import { ColumnType, TablePaginationConfig } from "antd/es/table";
import {
  REFUND_REASON,
  REFUND_REASON_LABEL,
} from "../../constants/refund-reason";
import { map } from "lodash";
import { FilterValue } from "antd/es/table/interface";

type Props = {
  setFilters: (data: Record<string, FilterValue | null>) => void;
  timeFilters: Array<string>;
  isLoading: boolean;
  pagination: TablePaginationConfig;
  providerFilters: Array<{
    id: string;
    name: string;
    idNumber: string;
  }>;
  refunds: Array<{
    id: string;
    time: string;
    amount: number;
    reason: REFUND_REASON;
    note: string | null;
    orderItem: {
      name: string;
      price: number;
      providerName: string;
    };
  }>;
};

export function ProductRefundComponent(props: Props) {
  const columns: ColumnType<Props["refunds"][number]>[] = [
    {
      width: 200,
      title: "退款時間",
      dataIndex: "time",
      filterSearch: true,
      filters: map(props.timeFilters, (time) => {
        return {
          text: new Date(time).toLocaleString(),
          value: time,
        };
      }),
      render: (time: string) => {
        return new Date(time).toLocaleString();
      },
    },
    {
      title: "供應商",
      dataIndex: ["orderItem", "providerName"],
      filterSearch: true,
      filters: map(props.providerFilters, (provider) => {
        return {
          text: `${provider.idNumber} - ${provider.name}`,
          value: provider.name,
        };
      }),
    },
    {
      title: "品名",
      dataIndex: ["orderItem", "name"],
    },
    {
      title: "售價",
      dataIndex: ["orderItem", "price"],
    },
    {
      title: "退款金額",
      dataIndex: "amount",
    },
    {
      title: "原因",
      dataIndex: "reason",
      render: (reason: REFUND_REASON) => {
        return REFUND_REASON_LABEL[REFUND_REASON[reason]] || reason;
      },
    },
    {
      title: "備註",
      dataIndex: "note",
    },
  ];

  return (
    <>
      <div className='text-2xl font-bold'>商品退款管理</div>
      <Table
        columns={columns}
        dataSource={props.refunds}
        loading={props.isLoading}
        pagination={props.pagination}
        rowKey='id'
        size='small'
        onChange={(pagination, filters, sorter, extra) => {
          props.setFilters({
            ...filters,
            providerNames: filters["orderItem.providerName"] || [],
          });
        }}
      />
    </>
  );
}
