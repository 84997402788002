export enum PROVIDER_EVENT_TYPES {
  // 延遲出餐
  LATE = "LATE",
  // 司機運輸費用
  TRANSPORTATION_COST = "TRANSPORTATION_COST",
  // 大樓標示錯誤來不及配送
  BUILDING_LABEL_ERROR = "BUILDING_LABEL_ERROR",
  // 其他
  OTHER = "OTHER",
}

export const PROVIDER_EVENT_TYPE_LABELS = {
  [PROVIDER_EVENT_TYPES.LATE]: "延遲出餐",
  [PROVIDER_EVENT_TYPES.TRANSPORTATION_COST]: "司機運輸費用",
  [PROVIDER_EVENT_TYPES.BUILDING_LABEL_ERROR]: "大樓標示錯誤來不及配送",
  [PROVIDER_EVENT_TYPES.OTHER]: "其他",
};

export const PROVIDER_EVENT_TYPE_OPTIONS = [
  {
    label: PROVIDER_EVENT_TYPE_LABELS[PROVIDER_EVENT_TYPES.LATE],
    value: PROVIDER_EVENT_TYPES.LATE,
  },
  {
    label: PROVIDER_EVENT_TYPE_LABELS[PROVIDER_EVENT_TYPES.TRANSPORTATION_COST],
    value: PROVIDER_EVENT_TYPES.TRANSPORTATION_COST,
  },
  {
    label:
      PROVIDER_EVENT_TYPE_LABELS[PROVIDER_EVENT_TYPES.BUILDING_LABEL_ERROR],
    value: PROVIDER_EVENT_TYPES.BUILDING_LABEL_ERROR,
  },
  {
    label: PROVIDER_EVENT_TYPE_LABELS[PROVIDER_EVENT_TYPES.OTHER],
    value: PROVIDER_EVENT_TYPES.OTHER,
  },
];
