import { Select, SelectProps } from "antd";
import FormItem from "antd/es/form/FormItem";

type RoleSelectorComponentProps = {
  multiple?: boolean;
  options: SelectProps["options"];
  isLoading: boolean;
  required?: boolean;
};

export function RoleSelectorComponent(props: RoleSelectorComponentProps) {
  return (
    <FormItem
      label='角色'
      name={props.multiple ? "roleIds" : "roleId"}
      rules={[{ required: props.required, message: "請選擇角色" }]}
    >
      <Select
        loading={props.isLoading}
        mode={props.multiple ? "multiple" : undefined}
        optionFilterProp='label'
        options={props.options}
        placeholder='請選擇角色'
      />
    </FormItem>
  );
}
