import { DatePicker, Form, FormInstance, Radio } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_TW';
import FormItem from 'antd/es/form/FormItem';
import { EXPORT_PERIOD_OPTIONS } from '../../constants/statement-form';
import { ProviderSelectorContainer } from '../../shared/provider-selector/provider-selector.containers';
import { FunctionInfoComponent } from '../../shared/function-info.component';

type Props = {
  form: FormInstance;
};

export function StatementExportFormComponent(props: Props) {
  return (
    <Form form={props.form}>
      <FormItem>
        <span className="text-red-400">匯出會花費數分鐘，請不要關閉視窗。</span>
      </FormItem>
      <FormItem
        label="月份"
        name="month"
        rules={[{ required: true, message: '請選擇要匯出的月份' }]}
      >
        <DatePicker picker="month" locale={locale} />
      </FormItem>
      <FormItem
        label="期間"
        name="period"
        rules={[{ required: true, message: '請選擇要匯出的期間' }]}
      >
        <Radio.Group options={EXPORT_PERIOD_OPTIONS} />
      </FormItem>
      <ProviderSelectorContainer
        multiple
        label={
          <div className="flex items-center gap-2">
            <span>供應商</span>
            <FunctionInfoComponent
              title={
                <ol>
                  <li>若不選擇供應商，將會匯出所有供應商的對帳單。</li>
                  <li>
                    若選中的供應商在該期間沒有售出任何商品，系統將會自動略過該供應商。
                  </li>
                </ol>
              }
            />
          </div>
        }
      />
    </Form>
  );
}
