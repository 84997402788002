import { Select, SelectProps } from "antd";
import FormItem from "antd/es/form/FormItem";

type PermissionSelectorComponentProps = {
  multiple?: boolean;
  options: SelectProps["options"];
  isLoading: boolean;
  required?: boolean;
};

export function PermissionSelectorComponent(
  props: PermissionSelectorComponentProps
) {
  return (
    <FormItem
      label='權限'
      name={props.multiple ? "permissionIds" : "permissionId"}
      rules={[{ required: props.required, message: "請選擇權限" }]}
    >
      <Select
        loading={props.isLoading}
        mode={props.multiple ? "multiple" : undefined}
        optionFilterProp='label'
        options={props.options}
        placeholder='請選擇權限'
      />
    </FormItem>
  );
}
