import { Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { ReactNode, useMemo } from "react";

type Props = {
  isDisabled?: boolean;
  isLoading: boolean;
  isRequired?: boolean;
  label?: ReactNode;
  multiple?: boolean;
  providers: {
    id: string;
    name: string;
    idNumber: string;
  }[];
};

export function ProviderSelectorComponent(props: Props) {
  const options =
    useMemo(() => {
      return props.providers.map((provider) => ({
        label: `${provider.idNumber} - ${provider.name}`,
        value: provider.id,
      }));
    }, [props.providers]) || [];

  return (
    <FormItem
      name={props.multiple ? "providerIds" : "providerId"}
      label={props.label || "供應商"}
      rules={[{ required: props.isRequired, message: "請選擇供應商" }]}
    >
      <Select
        disabled={props.isDisabled}
        loading={props.isLoading}
        mode={props.multiple ? "multiple" : undefined}
        optionFilterProp='label'
        options={options}
        placeholder='請選擇供應商'
        showSearch
      />
    </FormItem>
  );
}
