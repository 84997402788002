import { Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect } from 'react';

export function DriversForm(props: {
  form: FormInstance;
  data?: {
    id: string;
    idNumber: string;
    name: string;
    address: string;
    phone: string;
    carNumber: string;
    carModel: string;
    carColor: string;
  };
}) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item
        label="代號"
        name="idNumber"
        rules={[{ required: true, message: '請輸入代號' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="名字"
        name="name"
        rules={[{ required: true, message: '請輸入名字' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="地址"
        name="address"
        rules={[{ required: true, message: '請輸入地址' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="電話"
        name="phone"
        rules={[{ required: true, message: '請輸入電話' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="車牌"
        name="carNumber"
        rules={[{ required: true, message: '請輸入車牌' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="車型"
        name="carModel"
        rules={[{ required: false, message: '請輸入車型' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="車色"
        name="carColor"
        rules={[{ required: false, message: '請輸入車色' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
