import {
  useCreateRole,
  useDeleteRole,
  useRoles,
  useUpdateRole,
} from "../../utils/api.hook";
import { usePagination } from "../../shared/usePagintation";
import { RolesComponent } from "./roles-component";

export function RolesContainer() {
  const pagination = usePagination();
  const {
    data: { roles, total } = {},
    isLoading,
    isFetching,
  } = useRoles({
    take: pagination.take,
    skip: pagination.skip,
  });
  const { mutateAsync: createRole, isLoading: isCreating } = useCreateRole();
  const { mutateAsync: deleteRole, isLoading: isDeleting } = useDeleteRole();
  const { mutateAsync: updateRole, isLoading: isUpdating } = useUpdateRole();

  return (
    <RolesComponent
      createRole={createRole}
      deleteRole={deleteRole}
      updateRole={updateRole}
      isLoading={
        isLoading || isFetching || isCreating || isDeleting || isUpdating
      }
      roles={roles || []}
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
