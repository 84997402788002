import { map } from 'lodash';

export enum PRODUCT_STATE {
  SUPPLYING = 'SUPPLYING',
  DISCONTINUED = 'DISCONTINUED',
}

export const PRODUCT_STATE_LABEL = {
  [PRODUCT_STATE.SUPPLYING]: '供應中',
  [PRODUCT_STATE.DISCONTINUED]: '停售',
};

export const PRODUCT_STATE_OPTIONS = map(PRODUCT_STATE, (value, key) => ({
  label: PRODUCT_STATE_LABEL[value],
  value,
}));

export const PRODUCT_STATE_FILTERS = map(PRODUCT_STATE, (value, key) => ({
  text: PRODUCT_STATE_LABEL[value],
  value,
}));
