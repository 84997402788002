import { map } from "lodash";
import { useListRoles } from "../../utils/api.hook";
import { RoleSelectorComponent } from "./role-selector.component";

type Props = {
  multiple?: boolean;
  required?: boolean;
};

export function RoleSelectorContainer(props: Props) {
  const { data: { roles } = {}, isLoading, isFetching } = useListRoles();

  return (
    <RoleSelectorComponent
      isLoading={isLoading || isFetching}
      multiple={props.multiple}
      options={
        map(roles, (role) => ({ label: role.name, value: role.id })) || []
      }
    />
  );
}
