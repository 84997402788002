import { useAuthStore } from "../utils/stores/useAuthStore";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";

type Props = RouteProps;

export function PrivateRoute(props: Props) {
  const auth = useAuthStore();
  const { pathname } = useLocation();

  return auth.token ? (
    <Outlet />
  ) : (
    <Navigate
      to='/login'
      state={{
        from: pathname,
      }}
      replace
    />
  );
}
