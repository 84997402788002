export enum STATEMENT_STATE {
  CREATED = "CREATED",
  TBD = "TBD",
  TBS = "TBS",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  PAID = "PAID",
  CANCELLED = "CANCELLED",
}

export const STATEMENT_STATE_LABEL: Record<STATEMENT_STATE, string> = {
  [STATEMENT_STATE.CREATED]: "已建立",
  [STATEMENT_STATE.TBD]: "店家確認中",
  [STATEMENT_STATE.TBS]: "有疑慮待解決",
  [STATEMENT_STATE.PENDING_PAYMENT]: "待匯款",
  [STATEMENT_STATE.PAID]: "已匯款",
  [STATEMENT_STATE.CANCELLED]: "已作廢",
};

export const STATEMENT_STATE_FILTERS: Array<{
  text: string;
  value: STATEMENT_STATE;
}> = [
  {
    text: STATEMENT_STATE_LABEL[STATEMENT_STATE.CREATED],
    value: STATEMENT_STATE.CREATED,
  },
  {
    text: STATEMENT_STATE_LABEL[STATEMENT_STATE.TBD],
    value: STATEMENT_STATE.TBD,
  },
  {
    text: STATEMENT_STATE_LABEL[STATEMENT_STATE.TBS],
    value: STATEMENT_STATE.TBS,
  },
  {
    text: STATEMENT_STATE_LABEL[STATEMENT_STATE.PENDING_PAYMENT],
    value: STATEMENT_STATE.PENDING_PAYMENT,
  },
  {
    text: STATEMENT_STATE_LABEL[STATEMENT_STATE.PAID],
    value: STATEMENT_STATE.PAID,
  },
  {
    text: STATEMENT_STATE_LABEL[STATEMENT_STATE.CANCELLED],
    value: STATEMENT_STATE.CANCELLED,
  },
];
