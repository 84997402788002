import { TablePaginationConfig } from "antd";
import { useState } from "react";

export function usePagination(
  {
    defaultPageSize = 10,
  }: {
    defaultPageSize: number;
  } = {
    defaultPageSize: 10,
  }
): TablePaginationConfig & { skip: number; take: number } {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  return {
    current: currentPage,
    defaultPageSize,
    pageSizeOptions: ["10", "20", "30", "40", "50", "100", "200"],
    showQuickJumper: true,
    skip: (currentPage - 1) * pageSize,
    take: pageSize,
    showTotal: (to, range) => `${range[0]}-${range[1]} of ${to} items`,
    onShowSizeChange: (current, size) => {
      setPageSize(size);
    },
    onChange: (page) => {
      setCurrentPage(page);
    },
  };
}
