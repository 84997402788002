import { Space, Table, DatePicker, TablePaginationConfig, Input } from 'antd';
import type { Dayjs } from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import locale from 'antd/es/date-picker/locale/zh_TW';

import schedules from '../schedules';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useCallback } from 'react';

const WEEK_TRANSLATE = ['日', '一', '二', '三', '四', '五', '六'];

type ScheduleProviderOverviewComponentProps = {
  listData:
    | []
    | ({
        lastPickTime?: number;
        pickedTime: number[];
      } & schedules.Provider)[];
  isLoading: boolean;
  changeTimeRange: (data: { startTime: Dayjs; endTime: Dayjs }) => void;
  deafultRange: {
    startTime: Dayjs;
    endTime: Dayjs;
  };
  timeRange: {
    startTime: Dayjs;
    endTime: Dayjs;
  };
  setKeyword: (keyword: string) => void;
  setFilters: (filter: Record<string, any>) => void;
  pagination?: TablePaginationConfig;
};

function aggregatePickedTime(times: number[]): string {
  const groups: string[] = [];

  if (times.length === 0) {
    return '';
  }

  times.sort();

  let start = times[0];
  let end = start;

  for (let i = 1; i < times.length; i++) {
    const currentDay = dayjs(end).get('day');
    const nextDay = dayjs(times[i]).get('day');

    if (
      times[i] === end + 86400000 ||
      (currentDay === 5 && nextDay === 1 && times[i] === end + 86400000 * 3)
    ) {
      end = times[i];
    } else {
      groups.push(
        start === end
          ? `${dayjs(start).format('YYYY-MM-DD')} (${WEEK_TRANSLATE[dayjs(start).get('day')]})`
          : `${dayjs(start).format('YYYY-MM-DD')} (${WEEK_TRANSLATE[dayjs(start).get('day')]})~${dayjs(end).format('YYYY-MM-DD')} (${WEEK_TRANSLATE[dayjs(end).get('day')]})`
      );
      start = times[i];
      end = start;
    }
  }
  groups.push(
    start === end
      ? `${dayjs(start).format('YYYY-MM-DD')} (${WEEK_TRANSLATE[dayjs(start).get('day')]})`
      : `${dayjs(start).format('YYYY-MM-DD')} (${WEEK_TRANSLATE[dayjs(start).get('day')]})~${dayjs(end).format('YYYY-MM-DD')} (${WEEK_TRANSLATE[dayjs(end).get('day')]})`
  );

  return groups.join(' 、 ');
}

const columns: ColumnType<
  ScheduleProviderOverviewComponentProps['listData'][number]
>[] = [
  {
    width: 100,
    title: '編號',
    dataIndex: 'idNumber',
    fixed: 'left',
  },
  {
    title: '簡稱',
    width: 150,
    dataIndex: 'nickname',
    fixed: 'left',
  },
  {
    title: '名稱',
    dataIndex: 'name',
  },
  {
    dataIndex: 'pickedTime',
    title: '配餐天數',
    align: 'center',
    render: (pickedTime) => pickedTime.length || '',
  },
  {
    dataIndex: 'pickedTime',
    title: '配餐日期',
    render: (pickedTime) => aggregatePickedTime(pickedTime),
  },
];

export function ScheduleOverviewComponent(
  props: ScheduleProviderOverviewComponentProps
) {
  const onRangePickerChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    props.changeTimeRange({
      startTime: dates?.[0] || props.deafultRange.startTime,
      endTime: dates?.[1] || props.deafultRange.endTime,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onKeywordChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      props.setKeyword(e.target.value);
    }, 500),
    []
  );

  return (
    <Space direction="vertical" className="w-full page-schedule-meals">
      <div className="text-2xl font-bold">供應商配餐總覽</div>

      <Table
        rowKey="id"
        dataSource={props.listData}
        loading={props.isLoading}
        columns={columns}
        size="small"
        pagination={props.pagination}
        onChange={(pagination, filters) => {
          props.setFilters(filters);
        }}
        scroll={{ x: 'max-content' }}
        title={() => (
          <TableHeader
            value={props.timeRange}
            onChange={onRangePickerChange}
            onKeywordChange={onKeywordChange}
          />
        )}
      />
    </Space>
  );
}

type TableHeaderProps = {
  value: {
    startTime: Dayjs;
    endTime: Dayjs;
  };
  onChange: (dates: null | (Dayjs | null)[], dateStrings: string[]) => void;
  onKeywordChange: DebouncedFunc<(e: any) => void>;
};

const TableHeader = ({
  value,
  onChange,
  onKeywordChange,
}: TableHeaderProps) => {
  return (
    <div className="flex flex-col items-start gap-2">
      <Space>
        <span>配餐日期</span>
        <DatePicker.RangePicker
          presets={[
            {
              label: '今天',
              value: [dayjs().startOf('date'), dayjs().endOf('date')],
            },
            {
              label: '昨天',
              value: [
                dayjs().startOf('date').subtract(1, 'day'),
                dayjs().endOf('date').subtract(1, 'day'),
              ],
            },
            {
              label: '本週',
              value: [dayjs().startOf('week'), dayjs().endOf('week')],
            },
            {
              label: '上週',
              value: [
                dayjs().startOf('week').subtract(1, 'week'),
                dayjs().endOf('week').subtract(1, 'week'),
              ],
            },
            {
              label: '本月',
              value: [dayjs().startOf('month'), dayjs().endOf('month')],
            },
            {
              label: '前月',
              value: [
                dayjs().startOf('month').subtract(1, 'month'),
                dayjs().endOf('month').subtract(1, 'month'),
              ],
            },
          ]}
          value={[value.startTime, value.endTime]}
          onChange={onChange}
          locale={locale}
        />
      </Space>
      <div className="flex items-center gap-4">
        <span className="shrink-0">關鍵字</span>
        <Input placeholder="輸入關鍵字搜尋名稱" onChange={onKeywordChange} />
      </div>
    </div>
  );
};
