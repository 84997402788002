import { Button, Form, FormInstance, Input, InputNumber, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect } from 'react';
import { REFUND_REASON_OPTIONS } from '../constants/refund-reason';
import { get } from 'lodash';

type Props = {
  form: FormInstance;
  data: any;
};

export function RefundFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  console.log(props.data.inStorePrice || props.data.cost);

  return (
    <Form
      form={props.form}
      initialValues={
        {
          ...props.data,
          fee:
            get(props.data, 'refund.fee') ||
            (props.data.price - (props.data.inStorePrice || props.data.cost)) *
              props.data.quantity,
        } || {
          fee:
            (props.data.price - (props.data.inStorePrice || props.data.cost)) *
            props.data.quantity,
        }
      }
    >
      <FormItem label="買斷價">
        {props.data.cost ? props.data.cost * props.data.quantity : '尚未設定'}
      </FormItem>
      <FormItem label="店內價">
        {props.data.inStorePrice
          ? props.data.inStorePrice * props.data.quantity
          : '尚未設定'}
      </FormItem>
      <div className="flex gap-2">
        <FormItem
          label="部分退款"
          name="amount"
          rules={[
            { required: true, message: '請輸入部分退款' },
            { type: 'number', min: 0, message: '部分退款必須大於 0' },
            {
              type: 'number',
              max:
                (props.data.inStorePrice || props.data.cost) *
                props.data.quantity,
              message: '部分退款不可大於買斷價',
            },
          ]}
        >
          <InputNumber
            min={0}
            max={
              (props.data.inStorePrice || props.data.cost) * props.data.quantity
            }
          />
        </FormItem>
        <Button
          className="mt-1"
          danger
          size="small"
          type="primary"
          onClick={() => {
            props.form.setFieldsValue({
              amount:
                (props.data.inStorePrice || props.data.cost) *
                props.data.quantity,
            });
          }}
        >
          全額退款
        </Button>
      </div>
      <FormItem
        label="服務費"
        name="fee"
        rules={[
          { required: true, message: '請輸入服務費' },
          { type: 'number', min: 0, message: '服務費必須大於 0' },
          {
            type: 'number',
            max: (props.data.price - props.data.cost) * props.data.quantity,
            message: '服務費不可大於利潤',
          },
        ]}
      >
        <InputNumber
          min={0}
          max={(props.data.price - props.data.cost) * props.data.quantity}
        />
      </FormItem>
      <FormItem
        label="原因"
        name="reason"
        rules={[{ required: true, message: '請輸入退款原因' }]}
      >
        <Select placeholder="請選擇退款原因" options={REFUND_REASON_OPTIONS} />
      </FormItem>
      <FormItem label="備註" name="note">
        <Input />
      </FormItem>
    </Form>
  );
}
