import { usePermissions } from "../../utils/api.hook";
import { usePagination } from "../../shared/usePagintation";
import { PermissionsComponent } from "./permissions.component";

export function PermissionsContainer() {
  const pagination = usePagination({
    defaultPageSize: 20,
  });
  const {
    data: { permissions = [], total = 0 } = {},
    isLoading,
    isFetching,
  } = usePermissions();

  return (
    <PermissionsComponent
      isLoading={isLoading || isFetching}
      permissions={permissions || []}
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
