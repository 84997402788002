import { Checkbox, Form, FormInstance, Input, InputNumber, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect } from 'react';
import { PRODUCT_STATE_OPTIONS } from '../constants/product';

type Props = {
  data: any;
  form: FormInstance;
};

export function ProductFormComponent(props: Props) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form, props.data]);

  return (
    <Form form={props.form} initialValues={props.data} autoComplete="off">
      <FormItem name="provider" label="供應商名稱">
        <Input disabled />
      </FormItem>
      <FormItem
        name="state"
        label="狀態"
        rules={[{ required: true, message: '請選擇狀態' }]}
      >
        <Select options={PRODUCT_STATE_OPTIONS} />
      </FormItem>
      <FormItem
        name="idNumber"
        label="商品編號"
        rules={[{ required: true, message: '請輸入商品編號' }]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="name"
        label="商品名稱"
        rules={[{ required: true, message: '請輸入商品名稱' }]}
      >
        <Input />
      </FormItem>
      <FormItem
        name="cost"
        label="買斷價"
        rules={[{ type: 'number', min: 0, message: '買斷價必須大於等於 0' }]}
      >
        <InputNumber min={0} />
      </FormItem>
      <FormItem
        name="inStorePrice"
        label="店內價"
        rules={[{ type: 'number', min: 0, message: '店內價必須大於等於 0' }]}
      >
        <InputNumber min={0} />
      </FormItem>
      <FormItem
        name="salePrice"
        label="售價"
        rules={[{ type: 'number', min: 0, message: '售價必須大於等於 0' }]}
      >
        <InputNumber min={0} />
      </FormItem>
      <FormItem name="isToppings" label="加項" valuePropName="checked">
        <Checkbox />
      </FormItem>
    </Form>
  );
}
