import { Button, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';

type UpdateOrderItemFormComponentProps = {
  onFinish: (values: any) => void;
  data: {
    id: string;
    state: string;
  };
};

export function UpdateOrderItemFormComponent(
  props: UpdateOrderItemFormComponentProps
) {
  const [form] = useForm();

  return (
    <Form
      initialValues={props.data || {}}
      form={form}
      onFinish={props.onFinish}
    >
      <FormItem
        name="state"
        label="訂單狀態"
        rules={[{ required: true, message: '請選擇訂單狀態' }]}
      >
        <Select
          options={[
            { label: '已確認', value: '已確認' },
            { label: '未確認', value: '未確認' },
            { label: '取消訂單', value: '取消訂單' },
            { label: '取消訂單-退貨', value: '取消訂單-退貨' },
          ]}
        />
      </FormItem>
      <div className="flex gap-2 justify-end">
        <Button type="primary" htmlType="reset" ghost>
          重置
        </Button>
        <Button type="primary" htmlType="submit">
          儲存
        </Button>
      </div>
    </Form>
  );
}
