import { map } from "lodash";
import { useListUsers } from "../../utils/api.hook";
import { UserSelectorComponent } from "./user-selector.component";

type Props = {
  multiple?: boolean;
  required?: boolean;
};

export function UserSelectorContainer(props: Props) {
  const { data: { users } = {}, isLoading, isFetching } = useListUsers();

  return (
    <UserSelectorComponent
      isLoading={isLoading || isFetching}
      multiple={props.multiple}
      options={
        map(users, (user) => ({
          label: user.displayName || user.username,
          value: user.id,
        })) || []
      }
    />
  );
}
