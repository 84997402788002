import { create } from "zustand";
import { persist } from "zustand/middleware";
import { jwtDecode } from "jwt-decode";

type AuthStore = {
  user: any;
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
};

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      user: null,
      token: null,
      login: (token: string) => {
        const user = jwtDecode(token);

        set({ user, token });
      },
      logout: () => set({ user: null, token: null }),
    }),
    {
      name: "AUTH_STORE",
    }
  )
);
