import { sortBy } from "lodash";
import { useLocations } from "../../utils/api.hook";
import { LocationSelectorComponent } from "./location-selector.component";
import { ReactNode } from "react";

type Props = {
  multiple?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
};

export function LocationSelectorContainer(data: Props) {
  const { isLoading, data: { locations } = {} } = useLocations();

  return (
    <LocationSelectorComponent
      isDisabled={data.isDisabled}
      isLoading={isLoading}
      isRequired={data.isRequired}
      label={data.label}
      multiple={data.multiple}
      locations={sortBy(locations, 'idNumber')}
    />
  );
}
