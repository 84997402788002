import { Form, FormInstance, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect } from "react";

type IgnoredToppingsFormComponentProps = {
  form: FormInstance;
  data?: string[];
};

export function IgnoredToppingsFormComponent(
  props: IgnoredToppingsFormComponentProps
) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form
      form={props.form}
      initialValues={{
        toppings: props.data,
      }}
    >
      <FormItem noStyle>
        <p>忽略的加項將不會顯示在對帳單中做計算</p>
      </FormItem>
      <FormItem name='toppings' label='加項'>
        <Select mode='tags' placeholder='請輸入要忽略的加項' />
      </FormItem>
    </Form>
  );
}
