import { Button, Space, Table } from 'antd';
import useModal from 'antd/es/modal/useModal';
import { ColumnType, TablePaginationConfig } from 'antd/es/table';
import { map } from 'lodash';
import { TagComponent } from '../../shared/tag.component';
import { useForm } from 'antd/es/form/Form';
import { RoleFormComponent } from './role-form.component';

type RolesComponentProps = {
  deleteRole: (id: string) => Promise<any>;
  isLoading: boolean;
  pagination: TablePaginationConfig;
  roles: Array<{
    id: string;
    description: string;
    name: string;
    isProtected: boolean;
    permissions: Array<{
      id: string;
      name: string;
    }>;
    users: Array<{
      id: string;
      displayName: string;
      username: string;
    }>;
  }>;
  createRole: (data: {
    name: string;
    desc: string;
    userIds: string[] | undefined;
    permissionIds: string[] | undefined;
  }) => Promise<any>;
  updateRole: (data: {
    id: string;
    name: string;
    desc: string;
    userIds: string[] | undefined;
    permissionIds: string[] | undefined;
  }) => Promise<any>;
};

export function RolesComponent(props: RolesComponentProps) {
  const [model, contextHolder] = useModal();
  const [form] = useForm();

  const onCreateButtonClick = () => {
    model.confirm({
      title: '新增角色',
      icon: null,
      content: <RoleFormComponent form={form} />,
      okText: '新增',
      cancelText: '取消',
      onOk: async () => {
        const values = await form.validateFields();

        return props.createRole(values);
      },
    });
  };

  const onDeleteButtonClick = (role: RolesComponentProps['roles'][number]) => {
    model.confirm({
      cancelText: '取消',
      content: `確認刪除角色 ${role.name} 嗎？`,
      icon: null,
      okText: '刪除',
      title: '確認刪除',
      onOk: () => props.deleteRole(role.id),
    });
  };

  const onEditButtonClick = (role: RolesComponentProps['roles'][number]) => {
    model.confirm({
      title: '編輯角色',
      icon: null,
      content: (
        <RoleFormComponent
          form={form}
          role={{
            ...role,
            userIds: map(role.users, 'id'),
            permissionIds: map(role.permissions, 'id'),
          }}
        />
      ),
      okText: '保存',
      cancelText: '取消',
      onOk: async () => {
        const values = await form.validateFields();

        return props.updateRole({
          id: role.id,
          ...values,
        });
      },
    });
  };

  const columns: ColumnType<RolesComponentProps['roles'][number]>[] = [
    {
      title: '名稱',
      width: 150,
      dataIndex: 'name',
    },
    {
      title: '描述',
      width: 200,
      dataIndex: 'desc',
    },
    {
      title: '用戶',
      dataIndex: 'users',
      width: 150,
      render: (users) => (
        <div className="flex flex-wrap gap-2">
          {map(users, (user) => (
            <TagComponent title={user.displayName} key={user.id} />
          ))}
        </div>
      ),
    },
    {
      title: '權限',
      width: 500,
      dataIndex: 'permissions',
      render: (permissions) => (
        <div className="flex flex-wrap gap-2">
          {map(permissions, (permission) => (
            <TagComponent title={permission.name} key={permission.id} />
          ))}
        </div>
      ),
    },
    {
      title: '操作',
      width: 150,
      render: (record) => (
        <Space size="small">
          <Button
            size="small"
            type="primary"
            disabled={record.isProtected}
            onClick={() => onEditButtonClick(record)}
          >
            編輯
          </Button>
          <Button
            danger
            disabled={record.isProtected}
            onClick={() => onDeleteButtonClick(record)}
            size="small"
            type="primary"
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space size="small" direction="vertical" className="w-full">
      {contextHolder}
      <div className="text-2xl font-bold">角色管理</div>
      <Table
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            新增角色
          </Button>
        )}
        columns={columns}
        dataSource={props.roles}
        loading={props.isLoading}
        pagination={props.pagination}
        rowKey="id"
        size="small"
      />
    </Space>
  );
}
